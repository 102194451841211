import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { Tooltip } from "reactstrap";
import { TooltipOnEmptyInputOptions } from "../inputSpecs/tooltipOnEmptyInputSpec";
import { getI18nLocaleString } from "../i18n";
import { getLocalizedContent } from "../utils/localizedContent.util";
import { isClientSide } from "../utils/generic.util";
import namespaceList from "../i18n/namespaceList";

export interface TooltipOnEmptyInputProps {
    className?: string;
    inputValue: unknown;
    target: string;
    context: CMSProviderProperties;
    options: TooltipOnEmptyInputOptions;
}

/**
 * Displays a tooltip when the input is empty, but should be filled in by the user.
 *
 */
export const TooltipOnEmptyInput: React.FC<TooltipOnEmptyInputProps> = (props: TooltipOnEmptyInputProps): JSX.Element | null => {
    const {
        className,
        inputValue,
        target,
        context: { currentLocale, site },
        options: { useTooltipOnEmptyInput, tooltipOnEmptyInputOptions },
    } = props;

    const [tooltipIsOpen, setTooltipIsOpen] = React.useState<boolean>(!inputValue);
    const [delay, setDelay] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (inputValue && tooltipIsOpen) {
            setTooltipIsOpen(false);
        }

        if (!inputValue && !tooltipIsOpen && !tooltipOnEmptyInputOptions?.onlyShowInitially) {
            setTooltipIsOpen(true);
        }
    }, [tooltipOnEmptyInputOptions?.onlyShowInitially, inputValue]);

    React.useEffect(() => {
        if (tooltipIsOpen && delay) {
            setTimeout(() => {
                setDelay(false);
            }, 1500);
        }
    }, [delay, tooltipIsOpen]);

    if (!useTooltipOnEmptyInput || !tooltipOnEmptyInputOptions || delay) {
        return null;
    }

    const { localizedMessages, placement, useCloseIcon } = tooltipOnEmptyInputOptions;

    const tooltipMessage: string =
        getLocalizedContent({
            site,
            currentLocale,
            localizedContent: localizedMessages || [],
            keys: ["tooltipMessage"],
        })?.tooltipMessage || getI18nLocaleString(namespaceList.tooltipOnEmptyInput, "tooltipDefaultMessage");

    return (
        <React.Fragment>
            {isClientSide() && !!document.querySelector(`#${target}`) && (
                <Tooltip className={className} isOpen={tooltipIsOpen} placement={placement} target={target}>
                    <span className={"tooltip-message"}>{tooltipMessage}</span>
                    {useCloseIcon && <FontAwesome onClick={() => setTooltipIsOpen(false)} name="times" />}
                </Tooltip>
            )}
        </React.Fragment>
    );
};
