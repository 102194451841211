import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { Arrival, Duration } from "@maxxton/cms-mxts-api";
import { DateRangePicker, DateRangePickerShape } from "react-dates";
import { getPrices, handleMonthChangeEvent, isDayRangeBlocked, isDayRangeHighlighted, isOutsideRange, loadPrices, loadTotalPrices } from "../datepicker.util";
import { isEqual, noop, omit } from "lodash";

import { AvailabilityUtil } from "../../../utils/availability.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CalendarFormActions } from "../CalendarFormActions/CalendarFormActions";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateMap } from "../../../plugins/mxts/mxts.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { TotalPriceForDates } from "./dateRangePicker.types";
import { getI18nLocaleString } from "../../../i18n";
import { initialVisibleMonthThunk } from "../../../plugins/mxts";
import namespaceList from "../../../i18n/namespaceList";

type Moment = moment.Moment;

interface DateRangePickerWrapperState {
    availableDatesDetail?: Arrival[];
    totalPricesForSelectedDate: TotalPriceForDates[];
    isTotalPricesLoaded: boolean;
}

export type DateRangePickerWrapperProps = DateRangePickerShape & {
    availableDates?: DateMap;
    availableDurations?: number[];
    specialAvailableDates?: DateMap;
    specialAvailableDurations?: number[];
    loading: boolean;
    showHighlightedDates: boolean;
    showOnlySpecialDates: boolean;
    showBackdrop: boolean;
    isFetchingResults: boolean;
    minimumStayMsg?: string;
    onLoadComplete?: () => void;
    dynamicFilter?: DynamicFilter;
    context?: CMSProviderProperties;
    showCustomData?: boolean;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    localeForDatePicker?: string;
    modalLabel?: string;
    showCloseIcon?: boolean;
    showClearAndApplyButton?: boolean;
    onCalendarApply?: () => void;
    onCalendarClear?: () => void;
};

const defaultProps = {
    startDateId: "startDateId",
    endDateId: "endDateId",
    startDate: null,
    endDate: null,
    focusedInput: null,
    displayFormat: DATE_FORMAT.DISPLAY,
    orientation: "vertical",
    onDatesChange: noop,
    onFocusChange: noop,
    loading: false,
    hideKeyboardShortcutsPanel: true,
    showHighlightedDates: false,
    readOnly: true,
    showBackdrop: true,
    showOnlySpecialDates: false,
    showDefaultInputIcon: true,
    customInputIcon: <FontAwesome name="calendar" />,
    isFetchingResults: false,
};

class DateRangePickerWrapper extends React.Component<DateRangePickerWrapperProps, DateRangePickerWrapperState> {
    static defaultProps = defaultProps;

    constructor(props: DateRangePickerWrapperProps) {
        super(props);
        this.state = {
            availableDatesDetail: [],
            totalPricesForSelectedDate: [],
            isTotalPricesLoaded: true,
        };
    }

    componentDidMount() {
        const isNodePresentInterval = setInterval(() => {
            const startDatePickerElement = document.getElementsByName(this.props.startDateId);
            if (startDatePickerElement.length) {
                this.props.onLoadComplete?.();
                clearInterval(isNodePresentInterval);
            }
        }, 100);
        loadPrices(this);
    }

    private handleClose = () => this.props?.onFocusChange(null);
    private renderCalendarInfo = () => {
        const { showCloseIcon, modalLabel, onCalendarApply, onCalendarClear, showClearAndApplyButton } = this.props;
        return (
            <CalendarFormActions
                showCloseIcon={showCloseIcon}
                onCalendarClose={this.handleClose}
                modalLabel={modalLabel}
                onCalendarApply={onCalendarApply}
                onCalendarClear={onCalendarClear}
                showApply={showClearAndApplyButton}
                showClear={showClearAndApplyButton}
            />
        );
    };

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<DateRangePickerWrapperProps>) {
        const { availableDates, dynamicFilter, context, showTotalPrices, startDate, endDate, localeForDatePicker } = this.props;
        if (!isEqual(availableDates, nextProps.availableDates) || !isEqual(dynamicFilter, nextProps.dynamicFilter)) {
            loadPrices(this);
            if (showTotalPrices && startDate && (!endDate || !nextProps.endDate)) {
                const formattedDate = startDate.format(DATE_FORMAT.ELASTIC);
                const pricesWithDuration = this.state.availableDatesDetail?.filter((item: Arrival) => item.arrivalDate === formattedDate);
                const allEndDates: string[] = [];
                if (pricesWithDuration?.length) {
                    pricesWithDuration[0]?.durations?.map((duration: Duration) => allEndDates.push(duration?.resourcePrice?.departureDate));
                }
                loadTotalPrices(allEndDates, this);
            }
        }
        const locale = localeForDatePicker?.split(":")[1];
        if (localeForDatePicker && `${context?.currentLocale.name}:${locale}` === localeForDatePicker) {
            () => import(`moment/locale/${locale?.trim()}`);
        }
    }
    // eslint-disable-next-line max-lines-per-function
    render() {
        const {
            availableDates,
            specialAvailableDates,
            availableDurations,
            specialAvailableDurations,
            showOnlySpecialDates,
            startDate,
            focusedInput,
            loading,
            showHighlightedDates,
            showBackdrop,
            isFetchingResults,
            minimumStayMsg,
            showCustomData,
            context,
            dynamicFilter,
            endDate,
            customDataDisplayType,
            showPerNightPrices,
            showTotalPrices,
            localeForDatePicker,
            showClearAndApplyButton,
        } = this.props;
        const dateRangePickerProps = omit(this.props, [
            "availableDates",
            "availableDurations",
            "specialAvailableDates",
            "specialAvailableDurations",
            "showOnlySpecialDates",
            "loading",
            "showBackdrop",
            "showHighlightedDates",
            "isFetchingResults",
            "customDataDisplayType",
            "minimumStayMsg",
            "onLoadComplete",
            "showCustomData",
            "dynamicFilter",
            "context",
            "showPerNightPrices",
            "showTotalPrices",
            "localeForDatePicker",
            "showCloseIcon",
            "modalLabel",
            "onCalendarApply",
            "onCalendarClear",
            "showClearAndApplyButton",
        ]) as DateRangePickerShape;
        const { availableDatesDetail, totalPricesForSelectedDate, isTotalPricesLoaded } = this.state;
        const showDayHighlighted = showHighlightedDates ? isDayRangeHighlighted({ specialAvailableDates, specialAvailableDurations, startDate, focusedInput }) : undefined;
        const dates = showOnlySpecialDates ? specialAvailableDates : availableDates;
        const durations = showOnlySpecialDates ? specialAvailableDurations : availableDurations;
        const minimumNights = startDate && durations?.length ? durations[0] : 1;
        const minimumNightsMsg = minimumStayMsg?.replace(new RegExp("\\ [x] "), ` ${minimumNights.toString()} `);
        const firstAvailableDay: Moment | undefined = AvailabilityUtil.getFirstAvailableDay({ dateMap: availableDates });
        if (showCustomData) {
            dateRangePickerProps.onNextMonthClick = (currentMonthDate: Moment) => handleMonthChangeEvent(currentMonthDate, this, "Next");
            dateRangePickerProps.onPrevMonthClick = (currentMonthDate: Moment) => handleMonthChangeEvent(currentMonthDate, this, "Prev");
        }

        if (loading) {
            return (
                <div className="calendar-loader">
                    <div className="calendar-overlay" />
                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                    <DateRangePicker
                        startDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "startDate")}
                        endDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "endDate")}
                        {...dateRangePickerProps}
                        focusedInput={null}
                        startDateId="startDateLoading"
                        endDateId="endDateLoading"
                    />
                </div>
            );
        }
        const paramToFetchPrices = {
            startDate,
            endDate,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            showTotalPrices,
            availableDatesDetail,
            totalPricesForSelectedDate,
            isTotalPricesLoaded,
        };
        const locale = localeForDatePicker?.split(":")[1];
        if (localeForDatePicker && `${context?.currentLocale.name}:${locale}` === localeForDatePicker) {
            moment.locale(locale?.trim());
        }

        return (
            <>
                <div className={focusedInput && showBackdrop ? "backdrop-popup" : ""}></div>
                <DateRangePicker
                    startDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "startDate")}
                    endDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "endDate")}
                    {...dateRangePickerProps}
                    minDate={firstAvailableDay}
                    isDayBlocked={isDayRangeBlocked({
                        dates,
                        durations,
                        startDate,
                        focusedInput,
                        isFetchingResults,
                    })}
                    isOutsideRange={isOutsideRange(firstAvailableDay)}
                    isDayHighlighted={showDayHighlighted}
                    initialVisibleMonth={initialVisibleMonthThunk(dates, startDate)}
                    minimumNights={minimumNights}
                    renderCalendarInfo={this.renderCalendarInfo}
                    renderDayContents={(day, modifiers) => (
                        <React.Fragment>
                            {showCustomData ? getPrices({ ...paramToFetchPrices, day, modifiers }) : day.format("D")}
                            {modifiers.has("blocked-minimum-nights") && modifiers.has("hovered") && <div className={minimumNightsMsg ? "date-range-tooltip" : ""}>{minimumNightsMsg}</div>}
                        </React.Fragment>
                    )}
                />
            </>
        );
    }
}

export { DateRangePickerWrapper };
