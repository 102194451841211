import * as React from "react";

import { CustomLanguageCalendarWidgetOptions, getCustomLanguageCalendar, getModalLabelOptions } from "../../../components/utils";
import { DynamicDateFacet, warmupCache } from "./AvailabilityDate";
import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { TooltipOnEmptyInputOptions, tooltipOnEmptyInputSpec } from "../../../inputSpecs/tooltipOnEmptyInputSpec";
import { getI18nLocaleObject, getI18nLocaleObjectWithText } from "../../../i18n";

import { DATE_FORMAT } from "../../../utils/constants";
import { ModalLabel } from "../subject/Subject.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends TooltipOnEmptyInputOptions, LocalizedTitleOptions, CustomLanguageCalendarWidgetOptions {
    dateRange: boolean;
    inLineCalender: boolean;
    modalPopupCalendar: boolean;
    dateType: string;
    dateFormat: string;
    closeIcon: boolean;
    displayBtn: boolean;
    showInputs: boolean;
    styleIds: string[];
    showArrow: boolean;
    arrowColor: string;
    addIcon: boolean;
    iconOutside: boolean;
    iconRight: boolean;
    iconColor: string;
    disableAvailability: boolean;
    orientationOfCalendar: "horizontal" | "vertical";
    addInlineClearButton: boolean;
    highlightInput: boolean;
    highlightColor: string;
    showAvailabilityCalendar?: boolean;
    numberOfMonthsInRow?: string;
    addAdvancedClearButton: boolean;
    enableVerticalCalendarScrolling?: boolean;
    flexibleDates: NumberMultiSelectOption[];
    enableFlexibleDateOption: boolean;
    textTitleColor?: string;
    localizedFlexibleLabel: LocalizedFlexibleLabel[];
    localizedButtonLabel: localizedButtonLabel[];
    overwriteButtonLabel: boolean;
    showStartEndDateLabel: boolean;
    showCustomData?: boolean;
    showXAvailabilityCalendar?: boolean;
    numberOfMonthsToShow?: string;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    nrOfMonthsWithInlineVersion?: number;
    shouldSetStartAndEndDates?: boolean;
    showActivityAvailability?: boolean;
    showSpecificActivityAvailability?: boolean;
    daySizeWidth: string;
    enableModalLabel?: boolean;
    localizedModalLabel?: ModalLabel[];
    showClearAndApplyButton: boolean;
    enableStayExtension?: boolean;
}
export interface localizedButtonLabel {
    showButtonLabel: string;
    locale: string;
}
export interface LocalizedFlexibleLabel {
    showFlexibleLabel: string;
    locale: string;
}

const TARGETS = ["availability-date"];
export const ARRIVAL = "arrival";
export const DEPARTURE = "departure";

export const flexibleDateOptions = {
    EXACT_DATE: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "exactDate"),
    ONE_DAY: getI18nLocaleObjectWithText(namespaceList.admin, "day", "± 1 $"),
    TWO_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 2 $"),
    THREE_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 3 $"),
    FOUR_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 4 $"),
    FIVE_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 5 $"),
    SIX_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 6 $"),
    SEVEN_DAYS: getI18nLocaleObjectWithText(namespaceList.admin, "days", "± 7 $"),
};

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-availability-date-widget",
    name: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "availabilityDate"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "availabilityDates"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            {
                                variable: "dateRange",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "dateRange"),
                                default: false,
                                type: "checkbox",
                                groupName: "singleAndDateRangePickerGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "singleAndDateRangePickerGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "singleAndDateRangePickerGroupDescription"),
                            },
                            {
                                variable: "dateType",
                                label: getI18nLocaleObject(namespaceList.admin, "dateTypes"),
                                default: ARRIVAL,
                                optionList: [
                                    { value: ARRIVAL, label: ARRIVAL },
                                    { value: DEPARTURE, label: DEPARTURE },
                                ],
                                type: "select",
                                groupName: "singleAndDateRangePickerGroup",
                                visible: (item: WidgetOptions) => !item.dateRange,
                            },
                            {
                                variable: "dateFormat",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "selectDateFormat"),
                                default: ARRIVAL,
                                optionList: [
                                    { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
                                    { value: "MM-DD-YYYY", label: "MM-DD-YYYY" },
                                    { value: "DD-MMM-YY", label: "DD-MMM-YY" },
                                    { value: "MMM-DD-YYYY", label: "MMM-DD-YYYY" },
                                    { value: "MMM DD, YYYY", label: "MMM DD, YYYY" },
                                    { value: "DD MMM YYYY", label: "DD MMM. YYYY" },
                                    { value: DATE_FORMAT.DAY_DATE, label: DATE_FORMAT.DAY_DATE },
                                    { value: DATE_FORMAT.SHORT_DAY_AND_MONTH, label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "shortDayAndMonth") },
                                    { value: DATE_FORMAT.LOCALE_DATE, label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "localeDate") },
                                    { value: DATE_FORMAT.LOCALE_DATE_WITH_SHORT_DAY, label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "shortDayWithLocaleDate") },
                                    { value: DATE_FORMAT.LOCALE_DATE_WITH_DAY, label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "dayWithLocaleDate") },
                                ],
                                type: "select",
                                groupName: "singleAndDateRangePickerGroup",
                            },
                            {
                                variable: "inLineCalender",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "inLineCalender"),
                                default: false,
                                type: "checkbox",
                                groupName: "displayTypeCalendar",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "displayTypeCalendarGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "displayTypeCalendarGroupDescription"),
                            },
                            {
                                variable: "modalPopupCalendar",
                                label: getI18nLocaleObject(namespaceList.admin, "viewAsModal"),
                                default: false,
                                type: "checkbox",
                                groupName: "displayTypeCalendar",
                            },
                            {
                                variable: "addInlineClearButton",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "addInlineClearButton"),
                                default: false,
                                type: "checkbox",
                                groupName: "clearDatesButtonGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "clearDatesButtonGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "clearDatesButtonGroupDescription"),
                            },
                            {
                                variable: "addAdvancedClearButton",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "addAdvancedClearButton"),
                                default: false,
                                type: "checkbox",
                                groupName: "clearDatesButtonGroup",
                                visible: (options: WidgetOptions) => !options.inLineCalender || options.dateRange,
                            },
                            {
                                variable: "showAvailabilityCalendar",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showAvailabilityCalendar"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.showXAvailabilityCalendar,
                                groupName: "showXMonthCalendarGroup",
                                groupTitle: "Show X months Availability Calendar options",
                            },
                            {
                                variable: "showXAvailabilityCalendar",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showXAvailabilityCalendar"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.showAvailabilityCalendar,
                                groupName: "showXMonthCalendarGroup",
                            },
                            {
                                variable: "numberOfMonthsInRow",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "numberOfMonthsInRow"),
                                default: "4",
                                minimumNumberRange: 1,
                                maximumNumberRange: 12,
                                type: "number",
                                groupName: "showXMonthCalendarGroup",
                                visible: (item: WidgetOptions) => !!item.showAvailabilityCalendar || !!item.showXAvailabilityCalendar,
                            },
                            {
                                variable: "numberOfMonthsToShow",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "numberOfMonthsToShow"),
                                default: "4",
                                minimumNumberRange: 1,
                                type: "number",
                                groupName: "showXMonthCalendarGroup",
                                visible: (item: WidgetOptions) => !!item.showXAvailabilityCalendar,
                            },
                            {
                                variable: "showStartEndDateLabel",
                                label: getI18nLocaleObject(namespaceList.admin, "showStartEndDateLabel"),
                                default: false,
                                type: "checkbox",
                                groupName: "combineGroup",
                                groupTitle: "All the other options",
                            },
                            {
                                variable: "useTooltipOnEmptyInput",
                                label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "useTooltipOnEmptyInput"),
                                default: false,
                                type: "checkbox",
                                groupName: "combineGroup",
                            },
                            {
                                variable: "enableFlexibleDateOption",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "enableFlexibleDateOption"),
                                default: false,
                                type: "checkbox",
                                groupName: "flexibleDatesGroup",
                                groupTitle: "Flexible dates option",
                                groupDescription: "Configure these options for flexible dates",
                            },
                            localized({
                                variable: "localizedFlexibleLabel",
                                groupName: "flexibleDatesGroup",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showFlexibleLabel"),
                                        variable: "showFlexibleLabel",
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.enableFlexibleDateOption,
                            }),
                            {
                                variable: "flexibleDates",
                                type: "multiselect",
                                groupName: "flexibleDatesGroup",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "flexibleDates"),
                                optionList: () => [
                                    {
                                        value: 0,
                                        text: flexibleDateOptions.EXACT_DATE,
                                    },
                                    {
                                        value: 1,
                                        text: flexibleDateOptions.ONE_DAY,
                                    },
                                    {
                                        value: 2,
                                        text: flexibleDateOptions.TWO_DAYS,
                                    },
                                    {
                                        value: 3,
                                        text: flexibleDateOptions.THREE_DAYS,
                                    },
                                    {
                                        value: 4,
                                        text: flexibleDateOptions.FOUR_DAYS,
                                    },
                                    {
                                        value: 5,
                                        text: flexibleDateOptions.FIVE_DAYS,
                                    },
                                    {
                                        value: 6,
                                        text: flexibleDateOptions.SIX_DAYS,
                                    },
                                    {
                                        value: 7,
                                        text: flexibleDateOptions.SEVEN_DAYS,
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.enableFlexibleDateOption,
                            },
                            {
                                variable: "showCustomData",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showCustomData"),
                                default: false,
                                type: "checkbox",
                                groupName: "showCustomDataInCalendarGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showCustomDataInCalendarGroupTitle"),
                            },
                            {
                                variable: "customDataDisplayType",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "customDataDisplayType"),
                                type: "select",
                                default: "hover",
                                groupName: "showCustomDataInCalendarGroup",
                                optionList: [
                                    {
                                        value: "inline",
                                        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showPriceInline"),
                                    },
                                    {
                                        value: "hover",
                                        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showPriceOnHover"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.showCustomData,
                            },
                            {
                                variable: "daySizeWidth",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "daySizeWidth"),
                                type: "range",
                                default: "56",
                                minimumNumberRange: 40,
                                maximumNumberRange: 100,
                                groupName: "showCustomDataInCalendarGroup",
                                visible: (options: WidgetOptions) => !!options.showCustomData && options.customDataDisplayType === "inline",
                            },
                            {
                                variable: "showPerNightPrices",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "perNight"),
                                default: false,
                                type: "checkbox",
                                groupName: "showCustomDataInCalendarGroup",
                                visible: (options: WidgetOptions) => !!options.showCustomData,
                            },
                            {
                                variable: "showTotalPrices",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showTotalPrice"),
                                default: false,
                                type: "checkbox",
                                groupName: "showCustomDataInCalendarGroup",
                                visible: (options: WidgetOptions) => !!options.showCustomData,
                            },
                            {
                                variable: "nrOfMonthsWithInlineVersion",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "nrOfMonthsWithInlineVersion"),
                                default: 2,
                                type: "number",
                                groupName: "showCustomDataInCalendarGroup",
                                visible: (options: WidgetOptions) => !!options.showCustomData,
                            },
                            ...getCustomLanguageCalendar(),
                            {
                                variable: "showActivityAvailability",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showActivityAvailability"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.showSpecificActivityAvailability,
                                groupName: "activityDateGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "activityDateGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "activityDateGroupDescription"),
                            },
                            {
                                variable: "showSpecificActivityAvailability",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showSpecificActivityAvailability"),
                                default: false,
                                type: "checkbox",
                                groupName: "activityDateGroup",
                                visible: (options: WidgetOptions) => !options.showActivityAvailability,
                            },
                            {
                                variable: "shouldSetStartAndEndDates",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "setStartAndEndDates"),
                                type: "checkbox",
                                groupName: "activityDateGroup",
                                visible: (WidgetOptions: WidgetOptions) => !WidgetOptions.dateRange,
                            },
                            {
                                variable: "enableStayExtension",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "enableStayExtension"),
                                default: false,
                                type: "checkbox",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "stayExtensionGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "stayExtensionGroupDescription"),
                                groupName: "stayExtensionGroup",
                            },
                            {
                                variable: "disableAvailability",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "disableAvailability"),
                                default: false,
                                type: "checkbox",
                                groupName: "disableAvailabilityGroup",
                                groupTitle: "Disable availibilty option",
                                groupDescription: "Configure this option to disable availability when start date is selected.",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightInput",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightInput"),
                                type: "checkbox",
                                default: false,
                                groupName: "highlightInputGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "highlightInputGroupTitle"),
                            },
                            {
                                variable: "highlightColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightColor"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                groupName: "highlightInputGroup",
                                visible: (options: WidgetOptions) => options.highlightInput,
                            },
                            {
                                variable: "showClearAndApplyButton",
                                label: getI18nLocaleObject(namespaceList.admin, "showClearAndApplyButton"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.modalPopupCalendar && !options.inLineCalender,
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closeIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.modalPopupCalendar && !options.inLineCalender,
                            },
                            ...getModalLabelOptions(),
                            {
                                variable: "displayBtn",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBtn"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.inLineCalender,
                            },
                            {
                                variable: "showInputs",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showInputs"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayBtn,
                            },
                            {
                                variable: "overwriteButtonLabel",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "overwriteButtonLabel"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayBtn,
                            },
                            localized({
                                variable: "localizedButtonLabel",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showButtonLabel"),
                                        variable: "showButtonLabel",
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.displayBtn && options.overwriteButtonLabel,
                            }),
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                groupName: "iconGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "iconGroupTitle"),
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.addIcon,
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.addIcon,
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.addIcon,
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "orientationOfCalendar",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "orientationOfCalendar"),
                                type: "select",
                                default: "horizontal",
                                optionList: [
                                    {
                                        value: "horizontal",
                                        label: getI18nLocaleObject(namespaceList.admin, "horizontal"),
                                    },
                                    {
                                        value: "vertical",
                                        label: getI18nLocaleObject(namespaceList.admin, "vertical"),
                                    },
                                ],
                            },
                            {
                                variable: "enableVerticalCalendarScrolling",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "enableVerticalCalendarScrolling"),
                                default: false,
                                type: "checkbox",
                                visible: (WidgetOptions: WidgetOptions) => WidgetOptions.dateRange,
                            },

                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "tooltip"),
                    visible: (options: WidgetOptions) => !!options.useTooltipOnEmptyInput,
                    properties: [[tooltipOnEmptyInputSpec<WidgetOptions, keyof WidgetOptions>({ options: { variable: "tooltipOnEmptyInputOptions" } })]],
                },
            ],
        },
    ],
};

export const availabilityDateWidget: PageWidgetSpec<WidgetOptions> = {
    id: "availabilityDateWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "availabilityDate"),
    description: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "availabilityDateDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        dateRange: false,
        inLineCalender: false,
        modalPopupCalendar: false,
        dateType: ARRIVAL,
        styleIds: [],
        closeIcon: false,
        displayBtn: false,
        showArrow: false,
        arrowColor: "default",
        addIcon: false,
        iconColor: "default",
        iconOutside: false,
        dateFormat: "DD-MM-YYYY",
        iconRight: false,
        disableAvailability: false,
        orientationOfCalendar: "horizontal",
        addInlineClearButton: false,
        highlightInput: false,
        highlightColor: "theme-ctaColor",
        numberOfMonthsInRow: "4",
        addAdvancedClearButton: false,
        enableVerticalCalendarScrolling: false,
        flexibleDates: [],
        enableFlexibleDateOption: false,
        localizedFlexibleLabel: [],
        localizedButtonLabel: [],
        overwriteButtonLabel: false,
        showStartEndDateLabel: false,
        showCustomData: false,
        showXAvailabilityCalendar: false,
        numberOfMonthsToShow: "4",
        showInputs: false,
        daySizeWidth: "56",
        showClearAndApplyButton: false,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions, shouldReturnProps, allSites, flexboxOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        if (isServerSide()) {
            await warmupCache({
                dynamicContainerOptions,
                options: widget.options,
                context,
                availabilityState: {},
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                myEnvState: {},
                activityPlannerState: context.reduxStore.store.getState().activityPlannerState,
            });
        }
        return (
            <DynamicDateFacet
                dynamicContainerOptions={dynamicContainerOptions}
                options={widget.options}
                context={context}
                flexboxOptions={flexboxOptions}
                className={styleClasses}
                widgetOptionsForm={widgetOptionsForm}
                widgetId={widget._id}
            />
        );
    },
};
