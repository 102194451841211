import * as React from "react";
import * as moment from "moment";

import { Arrival, Duration } from "@maxxton/cms-mxts-api";
import { DayPickerRangeController, DayPickerRangeControllerShape } from "react-dates";
import { getPrices, handleMonthChangeEvent, isDayRangeBlocked, isDayRangeHighlighted, isOutsideRange, loadPrices, loadTotalPrices } from "../datepicker.util";
import { isEqual, noop, omit } from "lodash";

import { AvailabilityUtil } from "../../../utils/availability.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CalendarFormActions } from "../CalendarFormActions/CalendarFormActions";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateMap } from "../../../plugins/mxts/mxts.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { InputDayPickerRangeControllerWrapper } from "./InputDayPickerRangeControllerWrapper";
import { TotalPriceForDates } from "../DateRangePicker/dateRangePicker.types";
import { initialVisibleMonthThunk } from "../../../plugins/mxts";

type Moment = moment.Moment;

interface DayPickerRangeControllerWrapperState {
    availableDatesDetail?: Arrival[];
    totalPricesForSelectedDate: TotalPriceForDates[];
    isTotalPricesLoaded: boolean;
}

export type DayPickerRangeControllerWrapperProps = DayPickerRangeControllerShape & {
    showCloseIcon?: boolean;
    availableDates?: DateMap;
    availableDurations?: number[];
    specialAvailableDates?: DateMap;
    specialAvailableDurations?: number[];
    onCalendarClose: () => void;
    onCalendarClear: () => void;
    onCalendarApply: () => void;
    showHighlightedDates: boolean;
    showOnlySpecialDates: boolean;
    showBackdrop: boolean;
    context?: CMSProviderProperties;
    showInputs?: boolean;
    dynamicFilter?: DynamicFilter;
    showCustomData?: boolean;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    numberOfMonths?: number;
    localeForDatePicker?: string;
    modalLabel?: string;
    useAsModalPopup?: boolean;
    showClearAndApplyButton?: boolean;
    isInLineCalendar?: boolean;
};

const defaultProps = {
    startDate: null,
    endDate: null,
    focusedInput: null,
    displayFormat: DATE_FORMAT.DISPLAY,
    onDatesChange: noop,
    onFocusChange: noop,
    onCalendarApply: noop,
    onCalendarClose: noop,
    onCalendarClear: noop,
    initialVisibleMonth: noop,
    numberOfMonths: 2,
    hideKeyboardShortcutsPanel: true,
    showHighlightedDates: false,
    readOnly: true,
    showOnlySpecialDates: false,
    showBackdrop: true,
    showInputs: true,
    useAsModalPopup: false,
    isInLineCalendar: false,
};

class DayPickerRangeControllerWrapper extends React.Component<DayPickerRangeControllerWrapperProps, DayPickerRangeControllerWrapperState> {
    static defaultProps = defaultProps;

    constructor(props: DayPickerRangeControllerWrapperProps) {
        super(props);
        this.state = {
            availableDatesDetail: [],
            totalPricesForSelectedDate: [],
            isTotalPricesLoaded: true,
        };
    }

    componentDidMount() {
        loadPrices(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<DayPickerRangeControllerWrapperProps>) {
        const { availableDates, dynamicFilter, context, showTotalPrices, startDate, endDate, localeForDatePicker } = this.props;
        if (!isEqual(availableDates, nextProps.availableDates) || !isEqual(dynamicFilter, nextProps.dynamicFilter)) {
            loadPrices(this);
            if (showTotalPrices && startDate && (!endDate || !nextProps.endDate)) {
                const formattedDate = startDate.format(DATE_FORMAT.ELASTIC);
                const pricesWithDuration = this.state.availableDatesDetail?.filter((item: Arrival) => item.arrivalDate === formattedDate);
                const allEndDates: string[] = [];
                if (pricesWithDuration?.length) {
                    pricesWithDuration[0]?.durations?.map((duration: Duration) => allEndDates.push(duration?.resourcePrice?.departureDate));
                }
                loadTotalPrices(allEndDates, this);
            }
        }

        const locale = localeForDatePicker?.split(":")[1];
        if (localeForDatePicker && `${context?.currentLocale.name}:${locale}` === localeForDatePicker) {
            () => import(`moment/locale/${locale}`);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    render() {
        const {
            availableDates,
            specialAvailableDates,
            availableDurations,
            specialAvailableDurations,
            showOnlySpecialDates,
            showCloseIcon,
            focusedInput,
            startDate,
            endDate,
            showHighlightedDates,
            showBackdrop,
            context,
            showInputs,
            showCustomData,
            onFocusChange,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            showTotalPrices,
            numberOfMonths,
            modalLabel,
            localeForDatePicker,
            useAsModalPopup,
            showClearAndApplyButton,
            isInLineCalendar,
        } = this.props;
        const dayPickerRangeControllerProps = omit(this.props, [
            "availableDates",
            "availableDurations",
            "specialAvailableDates",
            "specialAvailableDurations",
            "showOnlySpecialDates",
            "showCloseIcon",
            "onCalendarClose",
            "onCalendarClear",
            "onCalendarApply",
            "showBackdrop",
            "showHighlightedDates",
            "displayFormat",
            "readOnly",
            "context",
            "showInputs",
            "dynamicFilter",
            "showCustomData",
            "showPerNightPrices",
            "customDataDisplayType",
            "numberOfMonths",
            "localeForDatePicker",
            "showTotalPrices",
            "modalLabel",
            "useAsModalPopup",
            "showClearAndApplyButton",
            "isInLineCalendar",
        ]) as DayPickerRangeControllerShape;
        const { availableDatesDetail, isTotalPricesLoaded, totalPricesForSelectedDate } = this.state;
        const renderCalendarInfo = showCloseIcon || modalLabel || showClearAndApplyButton || isInLineCalendar ? this.getCalendarFormActions : undefined;
        const firstAvailableDay: Moment | undefined = AvailabilityUtil.getFirstAvailableDay({ dateMap: availableDates });
        const showDayHighlighted = showHighlightedDates ? isDayRangeHighlighted({ specialAvailableDates, specialAvailableDurations, startDate, focusedInput }) : undefined;
        const dates = showOnlySpecialDates ? specialAvailableDates : availableDates;
        const durations = showOnlySpecialDates ? specialAvailableDurations : availableDurations;
        if (showCustomData) {
            dayPickerRangeControllerProps.onNextMonthClick = (currentMonthDate: Moment) => handleMonthChangeEvent(currentMonthDate, this, "Next");
            dayPickerRangeControllerProps.onPrevMonthClick = (currentMonthDate: Moment) => handleMonthChangeEvent(currentMonthDate, this, "Prev");
        }

        const paramToFetchPrices = {
            startDate,
            endDate,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            showTotalPrices,
            availableDatesDetail,
            totalPricesForSelectedDate,
            isTotalPricesLoaded,
        };
        const locale = localeForDatePicker?.split(":")[1];
        if (localeForDatePicker && `${context?.currentLocale.name}:${locale}` === localeForDatePicker) {
            moment.locale(locale);
        }
        return (
            <InputDayPickerRangeControllerWrapper
                context={context}
                showInputs={showInputs}
                startDate={startDate}
                endDate={endDate}
                setStartDate={this.setStartDate}
                setEndDate={this.setEndDate}
                setFocusedInput={this.setFocusedInput}
                modalLabel={modalLabel}
            >
                <DayPickerRangeController
                    {...dayPickerRangeControllerProps}
                    minDate={firstAvailableDay}
                    isDayBlocked={isDayRangeBlocked({ dates, durations, startDate, focusedInput })}
                    isOutsideRange={isOutsideRange(firstAvailableDay)}
                    isDayHighlighted={showDayHighlighted}
                    initialVisibleMonth={initialVisibleMonthThunk(dates, startDate)}
                    renderCalendarInfo={renderCalendarInfo}
                    // eslint-disable-next-line react/jsx-no-bind
                    renderDayContents={(day, modifiers) => <React.Fragment>{showCustomData ? getPrices({ ...paramToFetchPrices, day, modifiers }) : day.format("D")}</React.Fragment>}
                    numberOfMonths={numberOfMonths}
                />
            </InputDayPickerRangeControllerWrapper>
        );
    }

    private setStartDate = (newStartDate: Moment | undefined | null) => {
        const { onFocusChange, onDatesChange, endDate } = this.props;
        onDatesChange({ startDate: newStartDate || null, endDate });
        if (!newStartDate) {
            onFocusChange("startDate");
        }
    };

    private setFocusedInput = (focusedInput: "startDate" | "endDate" | null) => {
        const { onFocusChange } = this.props;
        onFocusChange(focusedInput);
    };

    private setEndDate = (newEndDate: Moment | undefined | null) => {
        const { onFocusChange, onDatesChange, startDate } = this.props;
        onDatesChange({ startDate, endDate: newEndDate || null });
        if (!newEndDate) {
            if (startDate) {
                onFocusChange("endDate");
            } else {
                onFocusChange("startDate");
            }
        }
    };

    private getCalendarFormActions = (): JSX.Element => {
        const { onCalendarClose, onCalendarClear, isInLineCalendar, onCalendarApply, modalLabel, showCloseIcon, useAsModalPopup, showClearAndApplyButton } = this.props;
        // Hide close icon and appy button for inline calendar
        const displayCloseIcon = !isInLineCalendar && showCloseIcon;
        const showApplyButton = !isInLineCalendar && showClearAndApplyButton;
        const showClearButton = isInLineCalendar || showClearAndApplyButton;
        return (
            <CalendarFormActions
                modalLabel={modalLabel}
                showCloseIcon={displayCloseIcon}
                onCalendarClose={onCalendarClose}
                onCalendarClear={onCalendarClear}
                onCalendarApply={onCalendarApply}
                useAsModalPopup={useAsModalPopup}
                showApply={showApplyButton}
                showClear={showClearButton}
            />
        );
    };
}

export { DayPickerRangeControllerWrapper };
