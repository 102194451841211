import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { ARRIVAL, DEPARTURE, WidgetOptions } from "./";
import { ActivityPlannerAction, ActivityPlannerActionType } from "../../../redux/actions/activityPlannerAction";
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from "reactstrap";
import { MxtsApi, ResourceDateAvailabilitySearchRequest } from "@maxxton/cms-mxts-api";
import { dispatchEmptyAction, fetchElasticResponse, generateRandomKey, getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getArrivalDepartureModel, getMxtsEnv } from "../../mxts";
import { getAvailableActivityDates, getLastDayOfMonth } from "../../page/activityPlanner/activityPlanner.util";
import { getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../../i18n";
import { isLandscapeOrientation, isPortraitOrientation } from "../../../utils/devicewidth.util";

import { ActionType } from "../../../redux/actions";
import { ActivityPlannerState } from "../../../redux/reducers/activityPlannerReducer";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityCalendar } from "./AvailabilityCalendar/AvailabilityCalendar";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { BoxModelUtil } from "../../../utils/boxmodel.util";
import ButtonGroup from "../../../components/button-group/ButtonGroup";
import { CalendarOrientation } from "../owner/owner.util";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateMap } from "../../mxts/mxts.types";
import { DateRangePickerWrapper } from "../../../components/datepicker/DateRangePicker/DateRangePickerWrapper";
import { DayPickerRangeControllerWrapper } from "../../../components/datepicker/DayPickerRangeController/DayPickerRangeControllerWrapper";
import { DayPickerSingleDateControllerWrapper } from "../../../components/datepicker/DayPickerSingleDateController/DayPickerSingleDateControllerWrapper";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { FocusedInputShape } from "react-dates";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { OverviewPanelChildOptions } from "../overview-panel";
import { SingleDatePickerWrapper } from "../../../components/datepicker/SingleDatePicker/SingleDatePickerWrapper";
import { State } from "../../../redux";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { TooltipOnEmptyInput } from "../../../components/TooltipOnEmptyInput";
import { connect } from "react-redux";
import { createDateMap } from "../../mxts/mxts.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getDatePickerClassNames } from "../../../components/datepicker/DayPickerRangeController/MaxxtonDateRangePickerStylingWrapper";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isClientSide } from "../../../utils/generic.util";
import { isEmpty } from "lodash";
import namespacesList from "../../../i18n/namespaceList";
import withEditModal from "../../../components/frontend-editing/WithEditModal";

type Moment = moment.Moment;

interface DateFacetProps extends DateFacetBaseProps, DateFacetStoreProps, DateFacetDispatchProps {}

interface DateFacetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    childOptions?: OverviewPanelChildOptions;
    className?: string;
    handleFrontendEditModal?: () => void;
}
interface DateFacetState {
    availableDates?: DateMap;
    availableDurations?: number[];
    startDate: Moment | null;
    endDate: Moment | null;
    focusedInput: FocusedInputShape | null;
    datefocus: boolean;
    disableWidget: boolean;
    isMobile: boolean;
    isApplyClicked: boolean;
    displayDateRangePicker: boolean;
    dateRangeModalOpen: boolean;
    singleDateModalOpen: boolean;
    dateLabel?: string | JSX.Element;
    finalStartDate: Moment | null;
    finalEndDate: Moment | null;
    arrivalDateModalOpen?: boolean;
    isComponentMounted: boolean;
    specialArrivalDateMap?: DateMap;
    modalSpecialAvailableDurations?: number[];
    modalLabel?: string;
    isLoading?: boolean;
}
interface DateFacetStoreProps {
    dynamicFilter: DynamicFilter;
    activityPlannerState: ActivityPlannerState;
    availabilityState: AvailabilityState;
    myEnvState: MyEnvState;
    isFrontendEditable?: boolean;
}

interface DateFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction | ActivityPlannerAction>;
}

class DateFacetWidget extends React.Component<DateFacetProps, DateFacetState> {
    private isArrival: boolean;
    private randomKey: string;
    private isStartDateSelected: boolean;

    constructor(props: DateFacetProps) {
        super(props);
        const { options } = props;
        const startDate: Moment | null = props.dynamicFilter.startdate ? moment(props.dynamicFilter.startdate, DATE_FORMAT.DEFAULT) : null;
        const endDate: Moment | null = props.dynamicFilter.enddate ? moment(props.dynamicFilter.enddate, DATE_FORMAT.DEFAULT) : null;
        this.state = {
            startDate,
            endDate,
            focusedInput: options.inLineCalender || options.modalPopupCalendar ? "startDate" : null,
            datefocus: false,
            disableWidget: true,
            isMobile: false,
            isApplyClicked: false,
            displayDateRangePicker: false,
            dateRangeModalOpen: false,
            singleDateModalOpen: false,
            finalEndDate: endDate,
            finalStartDate: startDate,
            arrivalDateModalOpen: false,
            isComponentMounted: false,
            isLoading: false,
        };
        this.isArrival = props.options.dateType === ARRIVAL;
        this.randomKey = generateRandomKey();
    }
    public componentDidMount() {
        const { dispatchAction, options, context, isActivityPlannerWidget } = this.props;
        const { currentLocale, site } = context;
        this.changeOrientation();
        if (isClientSide()) {
            window.addEventListener("orientationchange", this.changeOrientation);
        }

        this.setState({ isComponentMounted: true }, () => {
            const withAdvancedClearButton = options.addAdvancedClearButton ? "withClearBtn" : "";
            const startDatePickerElement = document.getElementsByName(`availabilityDateRangePickerStartDate-${withAdvancedClearButton}`);
            const endDatePickerElement = document.getElementsByName(`availabilityDateRangePickerEndDate-${withAdvancedClearButton}`);
            if (options.addAdvancedClearButton) {
                const startDateCloseButtonElement = document.getElementsByClassName(`remove-startdate-${this.randomKey}`);
                const endDateCloseButtonElement = document.getElementsByClassName(`remove-enddate-${this.randomKey}`);
                if (startDatePickerElement && startDateCloseButtonElement) {
                    startDatePickerElement.forEach((item, index) => item.after(startDateCloseButtonElement[index]));
                }
                if (endDatePickerElement && endDateCloseButtonElement) {
                    endDatePickerElement.forEach((item, index) => item.after(endDateCloseButtonElement[index]));
                }
            }

            if (options.showStartEndDateLabel) {
                const startDateLabelElement = document.getElementsByClassName("start-date-label-withStartEndLabel");
                const endDateLabelElement = document.getElementsByClassName("end-date-label-withStartEndLabel");

                if (startDatePickerElement.length && startDateLabelElement.length) {
                    startDatePickerElement.forEach((item, index) => item.after(startDateLabelElement[index]));
                }
                if (endDatePickerElement.length && endDateLabelElement.length) {
                    endDatePickerElement.forEach((item, index) => item.after(endDateLabelElement[index]));
                }
            }
        });
        if (!isActivityPlannerWidget) {
            dispatchEmptyAction(dispatchAction);
        }
        if (!this.state.availableDates || !this.state.availableDurations) {
            this.updateDates(this.props);
        }

        this.setState({
            disableWidget: !isClientLoggedIn(),
        });
        const modalLabel = options?.enableModalLabel && options?.localizedModalLabel && getLocalizedContent({ site, currentLocale, localizedContent: options?.localizedModalLabel })?.labelText;
        if (modalLabel) {
            this.setState({ modalLabel });
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<DateFacetProps>) {
        const { options } = this.props;
        const { availabilityState, dynamicFilter } = nextProps;
        const { startDate, endDate } = this.state;
        const response = this.props.availabilityState.availabilityResult;
        const nextResponse = nextProps.availabilityState.availabilityResult;
        const previousArrivalDates = response && response.response ? response.response.arrivalDates : [];
        const newArrivalDates = nextResponse && nextResponse.response ? nextResponse.response.arrivalDates : [];
        const previousDurations = response && response.response ? response.response.durations : [];
        const newDurations = nextResponse && nextResponse.response ? nextResponse.response.durations : [];
        ensureFetchingElasticResponse(nextProps);
        if (nextResponse && (!isEqual(previousArrivalDates, newArrivalDates) || !isEqual(previousDurations, newDurations))) {
            const nextDates = nextResponse.response.arrivalDates || [];
            const dates = (response && response.response.arrivalDates) || [];
            const nextDurations = nextResponse.response.durations || [];
            const durations = (response && response.response.durations) || [];
            if (nextDates !== dates || !this.state.availableDates || nextDurations !== durations || !this.state.availableDurations || this.state.availableDurations.length === 0) {
                this.updateDates(nextProps);
            }
        } else if (!this.state.availableDates || !this.state.availableDurations || Object.keys(this.state.availableDates).length === 0) {
            this.updateDates(nextProps);
        }
        if (response && nextResponse && nextResponse.filter.arrivalDate !== response.filter.arrivalDate) {
            this.setState({ endDate: null });
        }
        const arrivalDate = nextResponse && nextResponse.filter.arrivalDate;
        if (dynamicFilter.startdate || dynamicFilter.enddate) {
            this.setState({
                startDate: dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT) : null,
                endDate: dynamicFilter.enddate ? moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT) : null,
            });
        } else if (arrivalDate) {
            const startDate = moment(arrivalDate, DATE_FORMAT.ELASTIC);
            let endDate;
            this.setState({ startDate });
            const duration = nextResponse ? nextResponse.filter.duration : undefined;
            if (duration) {
                const arrival = new Date(arrivalDate);
                arrival.setDate(arrival.getDate() + duration);
                endDate = moment(arrival);
                this.setState({ endDate });
            }
            if (startDate && endDate && options.dateRange && options.displayBtn) {
                this.getDateLabel(startDate, endDate);
            }
        } else {
            this.setState({ startDate: null, endDate: null });
        }
        if (options.dateRange) {
            const dash = " - ";
            const startDateLabel = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY) : undefined;
            const endDateLabel = dynamicFilter.enddate ? moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY) : undefined;
            const dateLabel =
                startDateLabel && endDateLabel && startDateLabel !== endDateLabel ? (
                    <span>
                        {startDateLabel}
                        {dash}
                        {endDateLabel}
                    </span>
                ) : (
                    <span>{startDateLabel}</span>
                );
            this.setState({ dateLabel });
        }
        if (this.props.dynamicFilter.smartDatePopup !== dynamicFilter.smartDatePopup) {
            if (startDate) {
                this.setState({ startDate, focusedInput: "endDate" });
            } else {
                this.setState({ startDate, endDate, focusedInput: "startDate" });
            }
            if (options.dateRange && !options.inLineCalender && options.modalPopupCalendar && !options.displayBtn) {
                this.dateRangeModal();
            } else {
                this.handleArrivalDateModal();
            }
        }
        if (options?.showCustomData && availabilityState.env && dynamicFilter?.specialcode?.length && dynamicFilter?.specialcode) {
            getArrivalDepartureModel(
                this.props.context,
                availabilityState.env!,
                dynamicFilter,
                dynamicFilter.resourceid,
                dynamicFilter.unitid,
                startDate?.format(DATE_FORMAT.ELASTIC),
                dynamicFilter?.specialcode
            ).then((state) => {
                this.setState({ specialArrivalDateMap: state?.specialArrivalDateMap, modalSpecialAvailableDurations: state?.specialDurations });
            });
        }
        if (options.modalPopupCalendar && !startDate) {
            this.isStartDateSelected = false;
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<DateFacetProps>, nextState: Readonly<DateFacetState>): boolean {
        return (
            !isEqual(this.state, nextState) ||
            this.props.availabilityState.fetching !== nextProps.availabilityState.fetching ||
            !!nextProps.options.disableAvailability ||
            !isEqual(this.props.options, nextProps.options)
        );
    }

    public componentDidUpdate(prevProps: Readonly<DateFacetProps>): void {
        const { options } = prevProps;
        const { myEnvState, isMyEnvWidget } = this.props;
        const withAdvancedClearButton = options.addAdvancedClearButton ? "withClearBtn" : "";
        const startDatePickerElement = document.getElementsByName(`availabilityDateRangePickerStartDate-${withAdvancedClearButton}`);
        const endDatePickerElement = document.getElementsByName(`availabilityDateRangePickerEndDate-${withAdvancedClearButton}`);
        if (options.addAdvancedClearButton) {
            const startDateCloseButtonElement = document.getElementsByClassName("remove-startdate");
            const endDateCloseButtonElement = document.getElementsByClassName("remove-enddate");
            if (startDatePickerElement && startDateCloseButtonElement) {
                startDatePickerElement.forEach((item, index) => item.after(startDateCloseButtonElement[index]));
            }
            if (endDatePickerElement && endDateCloseButtonElement) {
                endDatePickerElement.forEach((item, index) => item.after(endDateCloseButtonElement[index]));
            }
        }

        if (options.showStartEndDateLabel) {
            const startDateLabelElement = document.getElementsByClassName("start-date-label");
            const endDateLabelElement = document.getElementsByClassName("end-date-label");

            if (startDatePickerElement.length && startDateLabelElement.length) {
                startDatePickerElement.forEach((item, index) => item.after(startDateLabelElement[index]));
            }
            if (endDatePickerElement.length && endDateLabelElement.length) {
                endDatePickerElement.forEach((item, index) => item.after(endDateLabelElement[index]));
            }
        }

        if (!isEqual(prevProps.myEnvState.ownerState?.selectedUnitId, myEnvState.ownerState?.selectedUnitId) && isMyEnvWidget) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.unitid,
                payload: { unitid: myEnvState.ownerState?.selectedUnitId },
            };
            this.props.dispatchAction(action);
        }
        this.changeOrientation();
        window.removeEventListener("resize", this.changeOrientation);
        window.addEventListener("resize", this.changeOrientation);
    }

    public componentWillUnmount() {
        window.removeEventListener("orientationchange", this.changeOrientation);
        window.removeEventListener("resize", this.changeOrientation);
    }

    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            startDate,
            endDate,
            availableDates,
            availableDurations,
            displayDateRangePicker,
            focusedInput,
            dateLabel,
            datefocus,
            disableWidget,
            arrivalDateModalOpen,
            isComponentMounted,
            modalLabel,
        } = this.state;
        const { availabilityState, className, options, childOptions, flexboxOptions, context, dynamicFilter, isMyEnvWidget, myEnvState, isFrontendEditable, handleFrontendEditModal } = this.props;
        const { highlightColor, highlightInput, showAvailabilityCalendar, flexibleDates, showXAvailabilityCalendar } = options;
        const { currentLocale, site } = context;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        const showFlexibleLabel = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedFlexibleLabel })?.showFlexibleLabel || "";
        const hideWidget = getHideWidgetClass(options, disableWidget);
        const flexiDateIndex = flexibleDates?.findIndex((flexiDate) => isEqual(flexiDate.value, Number(dynamicFilter.dateMargin)));
        const showButtonLabel = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButtonLabel })?.showButtonLabel || "";
        const flexibleDatesData: NumberMultiSelectOption[] = flexibleDates?.map(({ value, label }) => ({
            value,
            label:
                label &&
                getI18nLocaleStringFromParams(label)
                    ?.replace(/days/gi, getI18nLocaleString(namespacesList.admin, "days", currentLocale, site))
                    .replace(/day/gi, getI18nLocaleString(namespacesList.admin, "day", currentLocale, site)),
        }));
        const singleDatePickerStartDateLabelElement = options.showStartEndDateLabel ? (
            <span className="single-startDate-label"> {getI18nLocaleString(namespacesList.admin, "arrivalLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        const singleDatePickerEndDateLabelElement = options.showStartEndDateLabel ? (
            <span className="single-endDate-label"> {getI18nLocaleString(namespacesList.admin, "departureLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        if (hideWidget === null || (!myEnvState.ownerState?.selectedUnitId && isMyEnvWidget && !options.enableStayExtension)) {
            return null;
        }
        let counter = 0;
        React.Children.forEach(dateLabel, (child) => {
            if ((child as any).props.children) {
                counter++;
            }
        });
        const changeDefaultWidthDaySize = options?.showCustomData && options.customDataDisplayType === "inline" ? +options.daySizeWidth : undefined;

        const requiredPropsForCustomData = {
            startDate,
            endDate,
            context,
            dynamicFilter,
            availableDates,
            availableDurations,
            showCustomData: options?.showCustomData,
            showPerNightPrices: options?.showPerNightPrices,
            customDataDisplayType: options?.customDataDisplayType,
            showTotalPrices: options?.showTotalPrices,
            numberOfMonths: options?.nrOfMonthsWithInlineVersion,
            showHighlightedDates: dynamicFilter?.specialcode && options?.showCustomData,
            showOnlySpecialDates: dynamicFilter?.specialcode && options?.showCustomData,
            specialAvailableDurations: this.state?.modalSpecialAvailableDurations,
            specialAvailableDates: this.state?.specialArrivalDateMap,
        };

        return showAvailabilityCalendar || showXAvailabilityCalendar ? (
            <AvailabilityCalendar availableDates={availableDates} options={options} requiredPropsForCustomData={requiredPropsForCustomData} />
        ) : options.dateRange || (!options.dateRange && (childOptions?.isEditable || !childOptions?.isEditable)) ? (
            <div>
                <div id="date-picker-availability" className={`${getDatePickerClassNames({ options, className })} ${hideWidget}`}>
                    <LocalizedTitleAndLabel {...titleOptions} />
                    {options.dateRange && !options.inLineCalender && !options.modalPopupCalendar && !arrivalDateModalOpen && (
                        // eslint-disable-next-line max-len
                        <div
                            className={classNames("datepicker-inner search-filter-box--inner", {
                                "close-icon-added": options.closeIcon,
                                [`box-shadow-${highlightColor}`]: (isEmpty(startDate) || isEmpty(endDate)) && highlightInput && !highlightColor?.includes("rgba"),
                                "calendar-scroll-vertical": this.isVerticalCalendarScrollEnabled(),
                            })}
                            style={(isEmpty(startDate) || isEmpty(endDate)) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {}}
                        >
                            {options.displayBtn && (
                                <Button
                                    // eslint-disable-next-line max-len
                                    className={`dates-btn ${(dateLabel && counter > 0) || displayDateRangePicker ? "active" : ""} `}
                                    {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.toggleCalendar })}
                                    disabled={childOptions ? !childOptions.isEditable : false}
                                >
                                    {dateLabel && counter > 0 ? (
                                        options.overwriteButtonLabel ? (
                                            showButtonLabel
                                        ) : (
                                            dateLabel
                                        )
                                    ) : (
                                        <span className="filter-data-wrap">
                                            {options.overwriteButtonLabel ? showButtonLabel : getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "dates", currentLocale, site)}
                                        </span>
                                    )}
                                </Button>
                            )}
                            {options.displayBtn && !options.inLineCalender && displayDateRangePicker && (
                                <div className={`${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                                    <DayPickerRangeControllerWrapper
                                        startDate={startDate}
                                        endDate={endDate}
                                        focusedInput={focusedInput}
                                        onDatesChange={this.handleDatesChange}
                                        onFocusChange={this.handleFocusChange}
                                        showCloseIcon={options.closeIcon}
                                        orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                                        onCalendarApply={this.applyResults}
                                        onCalendarClear={this.clearResults}
                                        onCalendarClose={this.handleDayRangePickerClose}
                                        availableDates={availableDates}
                                        availableDurations={availableDurations}
                                        context={context}
                                        showInputs={options.showInputs}
                                        localeForDatePicker={options.useCustomLocale ? options?.localeForDatePicker : ""}
                                        daySize={changeDefaultWidthDaySize}
                                        dynamicFilter={dynamicFilter}
                                        showCustomData={options?.showCustomData}
                                        showPerNightPrices={options?.showPerNightPrices}
                                        customDataDisplayType={options?.customDataDisplayType}
                                        showTotalPrices={options?.showTotalPrices}
                                        numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                                        showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                                        showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                                        specialAvailableDurations={this.state?.modalSpecialAvailableDurations}
                                        specialAvailableDates={this.state?.specialArrivalDateMap}
                                        modalLabel={modalLabel}
                                        showClearAndApplyButton={options?.showClearAndApplyButton}
                                    />
                                </div>
                            )}
                            <div
                                className={`${this.state.displayDateRangePicker ? "backdrop-popup" : ""}`}
                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.toggleCalendar })}
                            ></div>
                            {!options.displayBtn && !options.inLineCalender && this.getDateRangePicker()}
                        </div>
                    )}
                    {!options.dateRange && !options.inLineCalender && !options.modalPopupCalendar && childOptions && !childOptions.isEditable && this.isArrival && startDate ? (
                        <div>
                            {this.isArrival && startDate
                                ? moment(startDate).format(options.dateFormat || DATE_FORMAT.DISPLAY)
                                : endDate
                                ? moment(endDate).format(options.dateFormat || DATE_FORMAT.DISPLAY)
                                : null}
                        </div>
                    ) : null}
                    {!options.dateRange && !options.inLineCalender && !options.modalPopupCalendar && (!childOptions || childOptions.isEditable || options.enableStayExtension) && (
                        <div
                            // eslint-disable-next-line max-len
                            className={`datepicker-wrap search-filter-box--inner ${startDate || endDate ? "date-selected" : ""} ${options.displayBtn ? "display-filter-btn" : ""}
                            ${
                                isEmpty(startDate) && highlightInput
                                    ? isEmpty(highlightColor)
                                        ? "box-shadow-theme-ctaColor"
                                        : !highlightColor.includes("rgba")
                                        ? `box-shadow-${highlightColor}`
                                        : ""
                                    : ""
                            }
                        `}
                            style={highlightInput && highlightColor?.includes("rgba") && isEmpty(startDate) ? BoxModelUtil.highlightStyling(highlightColor) : {}}
                        >
                            <React.Fragment>
                                <SingleDatePickerWrapper
                                    id={this.isArrival ? "startDate" + this.randomKey : "endDate" + this.randomKey}
                                    date={this.isArrival ? startDate : endDate}
                                    disabled={availabilityState.fetching}
                                    onDateChange={this.handleDateChange}
                                    focused={datefocus}
                                    onFocusChange={this.handleDateFocusChange}
                                    placeholder={getI18nLocaleString(
                                        namespacesList.dynamicAvailabilityDate,
                                        options.dateType === ARRIVAL ? "arrivalPlaceholder" : "departurePlaceholder",
                                        currentLocale,
                                        site
                                    )}
                                    displayFormat={options.dateFormat}
                                    showCloseIcon={options.closeIcon}
                                    onCalendarClose={this.handleDateClose}
                                    showClearDate={options.addInlineClearButton}
                                    context={context}
                                    availableDates={availableDates}
                                    onMonthChange={this.handleMonthChange}
                                    dynamicFilter={dynamicFilter}
                                    showCustomData={options?.showCustomData}
                                    showPerNightPrices={options?.showPerNightPrices}
                                    customDataDisplayType={options?.customDataDisplayType}
                                    showTotalPrices={options?.showTotalPrices}
                                    numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                                    daySize={changeDefaultWidthDaySize}
                                    showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                                    showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                                />
                                {isComponentMounted && options.showStartEndDateLabel && this.isArrival && singleDatePickerStartDateLabelElement}
                                {isComponentMounted && options.showStartEndDateLabel && !this.isArrival && singleDatePickerEndDateLabelElement}
                            </React.Fragment>
                        </div>
                    )}
                    {options.inLineCalender && options.dateRange && availableDates && (
                        <div className={`${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                            <DayPickerRangeControllerWrapper
                                startDate={startDate}
                                endDate={endDate}
                                focusedInput={focusedInput}
                                onDatesChange={this.handleDatesChange}
                                onFocusChange={this.handleFocusChange}
                                showCloseIcon={options.closeIcon}
                                onCalendarApply={this.applyResults}
                                onCalendarClear={this.clearResults}
                                onCalendarClose={this.toggleCalendar}
                                orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                                availableDates={availableDates}
                                availableDurations={availableDurations}
                                context={context}
                                showInputs={options.showInputs}
                                dynamicFilter={dynamicFilter}
                                showCustomData={options?.showCustomData}
                                showPerNightPrices={options?.showPerNightPrices}
                                customDataDisplayType={options?.customDataDisplayType}
                                showTotalPrices={options?.showTotalPrices}
                                numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                                localeForDatePicker={options.useCustomLocale ? options?.localeForDatePicker : ""}
                                daySize={changeDefaultWidthDaySize}
                                showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                                showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                                specialAvailableDurations={this.state?.modalSpecialAvailableDurations}
                                specialAvailableDates={this.state?.specialArrivalDateMap}
                                modalLabel={modalLabel}
                                isInLineCalendar={true}
                            />
                        </div>
                    )}
                    {options.inLineCalender && !options.dateRange && availableDates && (
                        <div className={`${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                            <DayPickerSingleDateControllerWrapper
                                date={this.isArrival ? startDate : endDate}
                                onDateChange={this.handleDateChange}
                                focused={datefocus}
                                onFocusChange={this.handleDateFocusChange}
                                showCloseIcon={options.closeIcon}
                                onCalendarClose={this.handleDateClose}
                                availableDates={availableDates}
                                showBackdrop={false}
                                availableDurations={availableDurations}
                                onCalendarClear={this.clearResults}
                                context={context}
                                dynamicFilter={dynamicFilter}
                                showCustomData={options?.showCustomData}
                                showPerNightPrices={options?.showPerNightPrices}
                                customDataDisplayType={options?.customDataDisplayType}
                                showTotalPrices={options?.showTotalPrices}
                                numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                                daySize={changeDefaultWidthDaySize}
                                showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                                showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                                specialAvailableDates={this.state?.specialArrivalDateMap}
                                isInLineCalendar={true}
                            />
                        </div>
                    )}
                    {!options.dateRange && !options.inLineCalender && options.modalPopupCalendar && (!childOptions || childOptions.isEditable) && (
                        <div
                            className={`datepicker-wrap search-filter-box--inner ${startDate || endDate ? "date-selected" : ""} ${options.displayBtn ? "display-filter-btn" : ""}
                            ${
                                isEmpty(startDate) && highlightInput
                                    ? isEmpty(highlightColor)
                                        ? "box-shadow-theme-ctaColor"
                                        : !highlightColor.includes("rgba")
                                        ? `box-shadow-${highlightColor}`
                                        : ""
                                    : ""
                            }
                        `}
                            style={highlightInput && highlightColor?.includes("rgba") && isEmpty(startDate) ? BoxModelUtil.highlightStyling(highlightColor) : {}}
                        >
                            {options.displayBtn ? (
                                <Button className={"dates-btn"} {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleSingleDateModal })}>
                                    {dateLabel && counter ? (
                                        dateLabel
                                    ) : (
                                        <span className="filter-data-wrap">
                                            {options.overwriteButtonLabel
                                                ? showButtonLabel
                                                : getI18nLocaleString(
                                                      namespacesList.dynamicAvailabilityDate,
                                                      options.dateType === ARRIVAL ? "arrivalPlaceholder" : "departurePlaceholder",
                                                      currentLocale,
                                                      site
                                                  )}
                                        </span>
                                    )}
                                </Button>
                            ) : (
                                <div className={"dates-btn"} {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleSingleDateModal })}>
                                    <SingleDatePickerWrapper
                                        id={this.isArrival ? "startDate" + this.randomKey : "endDate" + this.randomKey}
                                        date={this.isArrival ? startDate : endDate}
                                        focused={datefocus}
                                        placeholder={getI18nLocaleString(
                                            namespacesList.dynamicAvailabilityDate,
                                            options.dateType === ARRIVAL ? "arrivalPlaceholder" : "departurePlaceholder",
                                            currentLocale,
                                            site
                                        )}
                                        context={context}
                                        displayFormat={options.dateFormat}
                                        dynamicFilter={dynamicFilter}
                                        showCustomData={options?.showCustomData}
                                        showPerNightPrices={options?.showPerNightPrices}
                                        customDataDisplayType={options?.customDataDisplayType}
                                        showTotalPrices={options?.showTotalPrices}
                                        numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                                        daySize={changeDefaultWidthDaySize}
                                        showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                                        showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                                    />
                                </div>
                            )}
                            {this.singleDateModal()}
                        </div>
                    )}

                    {options.dateRange && !options.inLineCalender && options.modalPopupCalendar && (
                        <div
                            className={`datepicker-inner ${options.closeIcon ? "close-icon-added" : ""} search-filter-box--inner ${
                                (isEmpty(startDate) || isEmpty(endDate)) && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""
                            }`}
                            style={(isEmpty(startDate) || isEmpty(endDate)) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {}}
                        >
                            {options.displayBtn ? (
                                <Button
                                    className={classNames("dates-btn", { "custom-label": options.overwriteButtonLabel })}
                                    {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleDateModal })}
                                >
                                    {dateLabel && counter ? (
                                        options.overwriteButtonLabel ? (
                                            showButtonLabel
                                        ) : (
                                            dateLabel
                                        )
                                    ) : (
                                        <span className="filter-data-wrap">
                                            {options.overwriteButtonLabel ? showButtonLabel : getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "dates", currentLocale, site)}
                                        </span>
                                    )}
                                </Button>
                            ) : (
                                <div className={"datepicker-modal"} {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleDateModal })}>
                                    {this.getDateRangePicker()}
                                </div>
                            )}
                            {this.dateRangeModal()}
                        </div>
                    )}
                    {options.enableStayExtension && (
                        <Button
                            className={`dates-btn ${this.state.isLoading || !endDate ? "disabled" : ""}`}
                            {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleStayExtension })}
                        >
                            <span className="filter-data-wrap">
                                {options.overwriteButtonLabel ? showButtonLabel : getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "extendStayPlaceholder", currentLocale, site)}
                            </span>
                        </Button>
                    )}
                    {this.getArrivalDepartureDatePopup()}
                    <TooltipOnEmptyInput
                        className={classNames("datepicker-tooltip", `${flexboxOptions?.showMoment}`)}
                        inputValue={startDate}
                        target={`date-picker-${this.randomKey}`}
                        context={context}
                        options={options}
                    />
                </div>
                {/* Note:- When there is one flexible date option selected at admin side, it should show toggle switch icon otherwise it should show button group*/}
                {options.enableFlexibleDateOption ? (
                    <div className="toggle-switch-container">
                        <div className="flexible-label-text">{showFlexibleLabel}</div>
                        {flexibleDatesData?.length === 1 ? (
                            <div className="toggle-switch-wrapper">
                                <span>{flexibleDatesData[0].label as string}</span>
                                <ToggleSwitch onSwitchToggleClick={this.handleDateMargin} isDefaultSwitchToggled={!!dynamicFilter.dateMargin} />
                            </div>
                        ) : (
                            <ButtonGroup buttons={flexibleDatesData} onButtonClick={this.handleDateMargin} defaultSelectedButtonIndex={flexiDateIndex} />
                        )}
                    </div>
                ) : null}
            </div>
        ) : null;
    }
    /* jscpd:ignore-end */

    private handleDateMargin = (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => {
        const { dispatchAction, dynamicFilter, options } = this.props;
        const action = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.dateMargin,
            payload: {
                ...dynamicFilter,
                // When date margin is 0 that menas, Exact Date
                dateMargin: Number((event.target as HTMLButtonElement).name) || ((event.target as HTMLInputElement).checked && Number(options.flexibleDates[0].value)) || undefined,
            },
        };
        dispatchAction(action);
    };

    private handleRemoveDates = (action: string) => {
        if (action === dynamicFilterType.startdate) {
            this.clearResults();
        } else if (action === dynamicFilterType.enddate) {
            const { dispatchAction } = this.props;
            const action = this.getAction(dynamicFilterType.enddate, { enddate: undefined });
            dispatchAction(action);
        }
    };

    private getAction = (filter: dynamicFilterType, payload: DynamicFilter): FilterChangeAction => {
        payload = { ...payload, minprice: undefined, maxprice: undefined };
        return {
            type: ActionType.FilterChange,
            filter,
            payload,
        };
    };

    private handleDateModal = () => {
        this.setState({ dateRangeModalOpen: !this.state.dateRangeModalOpen });
    };
    private handleSingleDateModal = () => {
        this.setState({ singleDateModalOpen: !this.state.singleDateModalOpen });
    };

    private handleArrivalDateModal = () => {
        this.setState({ arrivalDateModalOpen: !this.state.arrivalDateModalOpen });
    };

    private getArrivalDepartureDatePopup = () => {
        const { startDate, endDate, focusedInput, arrivalDateModalOpen, availableDates, availableDurations, modalLabel } = this.state;
        const { options, childOptions, availabilityState, flexboxOptions, context, isFrontendEditable, handleFrontendEditModal } = this.props;
        const { currentLocale, site } = context;
        const changeDefaultWidthDaySize = options?.showCustomData && options.customDataDisplayType === "inline" ? +options.daySizeWidth : undefined;
        return (
            <Modal
                isOpen={arrivalDateModalOpen}
                toggle={this.handleArrivalDateModal}
                className={classNames("modal-smart-dates-calendar result-panel-button modal-dates-calendar modal-dates-calendar-mobile", {
                    "modal-horizontal":
                        (this.state.isMobile && isLandscapeOrientation()) ||
                        (options.orientationOfCalendar === CalendarOrientation.HORIZONTAL && !this.state.isMobile && isLandscapeOrientation()) ||
                        (!this.state.isMobile && options.orientationOfCalendar === CalendarOrientation.HORIZONTAL),
                    "modal-vertical":
                        (this.state.isMobile && isPortraitOrientation()) ||
                        (options.orientationOfCalendar === CalendarOrientation.VERTICAL && !this.state.isMobile && isPortraitOrientation()) ||
                        (!this.state.isMobile && options.orientationOfCalendar === CalendarOrientation.VERTICAL),
                })}
                id={`date-range-picker-${this.randomKey}`}
            >
                <ModalHeader tag="h4" toggle={this.handleArrivalDateModal} className="font-brand">
                    {getI18nLocaleString(namespacesList.widgetSearchfacet, "selectArrivalDepartureDate", currentLocale, site)}
                </ModalHeader>
                <ModalBody className={`modal-date-picker ${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                    <Card>
                        <DateRangePickerWrapper
                            startDateId={"availabilityModalDateRangePickerStartDate" + this.randomKey}
                            endDateId={"availabilityModalDateRangePickerEndDate" + this.randomKey}
                            startDatePlaceholderText={getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "arrivalPlaceholder", currentLocale, site)}
                            endDatePlaceholderText={getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "departurePlaceholder", currentLocale, site)}
                            disabled={childOptions ? !childOptions.isEditable : false}
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={this.handleDatesChange}
                            focusedInput={!options.disableAvailability && availabilityState.fetching ? null : focusedInput}
                            onFocusChange={this.handleFocusChange}
                            displayFormat={options.dateFormat}
                            showClearDates={options.addInlineClearButton}
                            availableDates={availableDates}
                            availableDurations={availableDurations}
                            showBackdrop={false}
                            orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                            daySize={changeDefaultWidthDaySize}
                            modalLabel={modalLabel}
                            showCloseIcon={options?.closeIcon}
                        />
                    </Card>
                </ModalBody>
                <ModalFooter className="filter-footer">
                    <NavLink
                        className="button button--primary button--m book-btn"
                        disabled={availabilityState.fetching}
                        style={{ color: "white" }}
                        {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleArrivalDateModal })}
                    >
                        {getI18nLocaleString(namespacesList.admin, "apply", currentLocale, site)}
                        {availabilityState.fetching && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                    </NavLink>
                </ModalFooter>
                <TooltipOnEmptyInput className={flexboxOptions?.showMoment} inputValue={startDate} target={`date-range-picker-${this.randomKey}`} context={context} options={options} />
            </Modal>
        );
    };

    /* jscpd:ignore-start */
    private dateRangeModal = () => {
        const { dateRangeModalOpen, startDate, endDate, availableDates, availableDurations, focusedInput, modalLabel } = this.state;
        const { context, flexboxOptions, options, availabilityState, dynamicFilter } = this.props;
        const { currentLocale, site } = context;
        const changeDefaultWidthDaySize = options?.showCustomData && options.customDataDisplayType === "inline" ? +options.daySizeWidth : undefined;
        return (
            <Modal
                isOpen={dateRangeModalOpen}
                toggle={this.handleDateModal}
                //  className={"date-range-picker modal-date-range-picker test"}
                className={classNames("date-range-picker modal-date-range-picker", {
                    "modal-horizontal":
                        isLandscapeOrientation() &&
                        ((options.orientationOfCalendar === CalendarOrientation.HORIZONTAL && !this.state.isMobile) ||
                            (options.orientationOfCalendar === CalendarOrientation.HORIZONTAL && isLandscapeOrientation())),
                    "modal-vertical":
                        (this.state.isMobile && isPortraitOrientation()) ||
                        (options.orientationOfCalendar === CalendarOrientation.VERTICAL && !this.state.isMobile && isPortraitOrientation()) ||
                        (options.orientationOfCalendar === CalendarOrientation.HORIZONTAL && this.state.isMobile) ||
                        (options.orientationOfCalendar === CalendarOrientation.VERTICAL && !this.state.isMobile),
                })}
                id={`modal-date-range-picker-${this.randomKey}`}
            >
                <ModalHeader tag="h4" toggle={this.handleDateModal} className="font-brand no-background">
                    {getI18nLocaleString(namespacesList.widgetSearchfacet, "selectdates", currentLocale, site)}
                </ModalHeader>
                <ModalBody className={`modal-date-picker ${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                    <DayPickerRangeControllerWrapper
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={this.handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={this.handleFocusChange}
                        availableDates={availableDates}
                        availableDurations={availabilityState?.fetching ? [] : availableDurations}
                        showBackdrop={!options.modalPopupCalendar}
                        orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                        context={context}
                        showInputs={options.showInputs}
                        localeForDatePicker={options.useCustomLocale ? options?.localeForDatePicker : ""}
                        daySize={changeDefaultWidthDaySize}
                        dynamicFilter={dynamicFilter}
                        showCustomData={options?.showCustomData}
                        showPerNightPrices={options?.showPerNightPrices}
                        customDataDisplayType={options?.customDataDisplayType}
                        showTotalPrices={options?.showTotalPrices}
                        numberOfMonths={options?.nrOfMonthsWithInlineVersion}
                        showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                        showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                        specialAvailableDurations={this.state?.modalSpecialAvailableDurations}
                        specialAvailableDates={this.state?.specialArrivalDateMap}
                        modalLabel={modalLabel}
                        showCloseIcon={options?.closeIcon}
                        useAsModalPopup={options?.modalPopupCalendar}
                    />
                    <div className="filter-button">
                        <a className="font-gray clear-text" onClick={this.clearResults}>
                            {getI18nLocaleString(namespacesList.admin, "clear", currentLocale, site)}
                        </a>
                        <a className="font-cta ml-auto apply-text" onClick={this.applyResults}>
                            {getI18nLocaleString(namespacesList.admin, "apply", currentLocale, site)}
                        </a>
                    </div>
                </ModalBody>
                <TooltipOnEmptyInput className={flexboxOptions?.showMoment} inputValue={startDate} target={`modal-date-range-picker-${this.randomKey}`} context={context} options={options} />
            </Modal>
        );
    };
    private singleDateModal = () => {
        const { context, flexboxOptions, options, availabilityState } = this.props;
        const { currentLocale, site } = context;
        const { singleDateModalOpen, startDate, endDate, availableDates, datefocus } = this.state;

        return (
            <Modal isOpen={singleDateModalOpen} toggle={this.handleSingleDateModal} className={"date-range-picker modal-single-date-picker"} id={`modal-single-date-picker-${this.randomKey}`}>
                <ModalHeader tag="h4" toggle={this.handleSingleDateModal} className="font-brand no-background">
                    {getI18nLocaleString(namespacesList.widgetSearchfacet, "selectdates", currentLocale, site)}
                </ModalHeader>
                <ModalBody className="modal-date-picker">
                    <DayPickerSingleDateControllerWrapper
                        date={this.isArrival ? startDate : endDate}
                        onDateChange={this.handleDateChange}
                        focused={datefocus}
                        onFocusChange={this.handleDateFocusChange}
                        showCloseIcon={options.closeIcon}
                        onCalendarClose={this.handleDateClose}
                        availableDates={availableDates}
                        loading={availabilityState.fetching}
                        showBackdrop={false}
                        orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                    />
                    <div className="filter-button">
                        <a className="font-gray clear-text" onClick={this.clearResults}>
                            {getI18nLocaleString(namespacesList.admin, "clear", currentLocale, site)}
                        </a>
                        <a className="font-cta ml-auto apply-text" onClick={this.applyResults}>
                            {getI18nLocaleString(namespacesList.admin, "apply", currentLocale, site)}
                        </a>
                    </div>
                </ModalBody>
                <TooltipOnEmptyInput className={flexboxOptions?.showMoment} inputValue={startDate} target={`date-single-date-picker-${this.randomKey}`} context={context} options={options} />
            </Modal>
        );
    };
    /* jscpd:ignore-end */

    private changeOrientation = () => {
        if (isPortraitOrientation() && !this.state.isMobile) {
            this.setState({ isMobile: true });
        } else if (isLandscapeOrientation() && this.state.isMobile) {
            this.setState({ isMobile: false });
        }
    };

    private handleDateClose = (): void => {
        const { datefocus } = this.state;
        this.setState(() => ({ datefocus: !datefocus }));
    };

    private handleDayRangePickerClose = () => this.setState({ displayDateRangePicker: false });

    private handleFocusChange = (focusedInput: FocusedInputShape): void => {
        const { startDate, endDate, arrivalDateModalOpen } = this.state;
        const { options } = this.props;
        let focus = focusedInput;
        if ((!startDate && focusedInput === "endDate" && !this.state.focusedInput) || (options.modalPopupCalendar && !startDate && focusedInput === "endDate" && !this.isStartDateSelected)) {
            focus = "startDate";
        }
        if ((options.inLineCalender || options.modalPopupCalendar) && !focusedInput) {
            focus = "startDate";
        }
        if (((!startDate || !endDate) && !focusedInput && arrivalDateModalOpen) || (!focusedInput && arrivalDateModalOpen)) {
            focus = this.state.focusedInput!;
        }
        this.setState(() => ({ focusedInput: focus }));
    };

    private handleDatesChange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }): void => {
        const startDateNotChanged = this.state.startDate === null || startDate === null ? false : startDate.isSame(this.state.startDate);
        const { options } = this.props;
        let isAvailabilityDisabled = false;
        let action: FilterChangeAction;
        if ((!options.displayBtn || options?.showCustomData) && options.disableAvailability) {
            action = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.updateDates,
                payload: {
                    ...this.props.dynamicFilter,
                    enddate: endDate && endDate.format(DATE_FORMAT.DEFAULT),
                    startdate: startDate && startDate.format(DATE_FORMAT.DEFAULT),
                },
            };
            if (startDateNotChanged) {
                isAvailabilityDisabled = true;
            }
        } else {
            action = {
                type: ActionType.FilterChange,
                filter: startDateNotChanged ? dynamicFilterType.enddate : dynamicFilterType.startdate,
                payload: startDateNotChanged
                    ? {
                          enddate: endDate && endDate.format(DATE_FORMAT.DEFAULT),
                      }
                    : {
                          startdate: startDate && startDate.format(DATE_FORMAT.DEFAULT),
                      },
            };
            if (options.modalPopupCalendar && startDate) {
                this.isStartDateSelected = true;
            }
            this.props.dispatchAction(action);
        }
        this.setState({ startDate: startDate ? startDate : null, endDate: endDate ? endDate : null, isApplyClicked: false }, () => {
            if (isAvailabilityDisabled) {
                this.props.dispatchAction(action);
            }
        });
    };

    private handleDateChange = (date: Moment): void => {
        const { options, dispatchAction, activityPlannerState, dynamicFilter } = this.props;
        if (this.isArrival) {
            this.setState({ startDate: date });
        } else {
            this.setState({ endDate: date });
        }
        let payload: { startdate?: string; enddate?: string } = this.isArrival
            ? { startdate: date ? date.format(DATE_FORMAT.DEFAULT) : undefined }
            : { enddate: date ? date.format(DATE_FORMAT.DEFAULT) : undefined };
        if (options.shouldSetStartAndEndDates && date) {
            payload = { startdate: date.format(DATE_FORMAT.DEFAULT), enddate: date.format(DATE_FORMAT.DEFAULT) };
        }
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: this.isArrival ? dynamicFilterType.startdate : dynamicFilterType.enddate,
            payload,
        };
        dispatchAction(action);

        if (options.shouldSetStartAndEndDates) {
            dispatchAction({
                type: ActionType.FilterChange,
                filter: !this.isArrival ? dynamicFilterType.startdate : dynamicFilterType.enddate,
                payload,
            });
        }
        if (dynamicFilter.resourceActivityDetailsIds || dynamicFilter.selectedActivities?.length || activityPlannerState.selectedActivities?.length) {
            const activityStateResetAction = {
                type: ActionType.activityPlanner,
                actionType: ActivityPlannerActionType.RESET_STATE,
                payload: {},
            };
            dispatchAction(activityStateResetAction);
            const activityDateChangeAction: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.activityDateChange,
                payload: {},
            };
            dispatchAction(activityDateChangeAction);
        }
    };

    private handleMonthChange = (day: Moment) => {
        const { options, childOptions, availabilityState, context, dynamicFilter } = this.props;
        const firstDayOfCurrentMonth = new Date(day.year(), day!.month(), 1);
        const todaysDate = new Date();
        (async () => {
            if (
                (options.showActivityAvailability || options.showSpecificActivityAvailability) &&
                availabilityState?.env &&
                !options.dateRange &&
                !options.inLineCalender &&
                !options.modalPopupCalendar &&
                (!childOptions || childOptions.isEditable) &&
                firstDayOfCurrentMonth >= todaysDate
            ) {
                const startDate = moment(firstDayOfCurrentMonth).format(DATE_FORMAT.MXTS);
                const endDate = moment(new Date(day.year(), day!.month() + 1, 0)).format(DATE_FORMAT.MXTS);
                const newAvailableDates = await getAvailableActivityDates({
                    startDate,
                    endDate,
                    env: availabilityState?.env,
                    context,
                    resortIds: dynamicFilter.resortids,
                    showSpecificActivityAvailability: options.showSpecificActivityAvailability,
                    resourceId: dynamicFilter.resourceid,
                });
                this.setState({
                    availableDates: { ...this.state.availableDates, ...newAvailableDates },
                });
            }
        })();
    };

    private handleDateFocusChange = (arg: { focused: boolean | null }): void => {
        this.setState(() => ({ datefocus: !!arg.focused }));
    };

    private handleStayExtension = async () => {
        const { context, myEnvState } = this.props;
        const { endDate } = this.state;
        this.setState({ isLoading: true });
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const currentReservation = myEnvState.selectedReservation;
        if (env && endDate && currentReservation) {
            const reservedResource = currentReservation.reservedResources?.[0];
            const startTime = moment.utc(reservedResource?.endDateTime);
            const endDateUTCString = endDate.utc().set({ hour: startTime.hour(), minute: startTime.minute(), second: startTime.second() }).toISOString();
            await MxtsApi.updateReservedResource(env, { endDateTime: endDateUTCString }, [
                { key: "reservationId", value: currentReservation.reservation.reservationId },
                { key: "reservedResourceId", value: currentReservation.reservedResources?.[0].reservedResourceId },
            ]).then(() => {
                this.setState({ isLoading: false });
                window.location.reload();
            });
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private updateDates = async (props: DateFacetProps): Promise<void> => {
        const { startDate, endDate } = this.state;
        const { context, availabilityState, options, childOptions, dynamicFilter, myEnvState } = props;
        if (startDate && endDate && props.options.dateRange && props.options.displayBtn) {
            this.getDateLabel(startDate, endDate);
        }
        const response = availabilityState.availabilityResult;
        if (
            (options.showActivityAvailability || options.showSpecificActivityAvailability) &&
            availabilityState?.env &&
            !options.dateRange &&
            !options.inLineCalender &&
            !options.modalPopupCalendar &&
            (!childOptions || childOptions.isEditable)
        ) {
            const today = moment();
            let startDate: string = today.format(DATE_FORMAT.MXTS);
            if (dynamicFilter.startdate) {
                const filterStartDate = moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT);
                if (filterStartDate.month() > today.month()) {
                    // If date is in a future month, fetch availability for entire month
                    startDate = filterStartDate.startOf("month").format(DATE_FORMAT.MXTS);
                }
            }
            const startDateObject = moment(startDate).toDate();
            const endDate = moment(getLastDayOfMonth(startDateObject)).format(DATE_FORMAT.MXTS);
            const availableDates = await getAvailableActivityDates({
                startDate,
                endDate,
                env: availabilityState?.env,
                context,
                resortIds: dynamicFilter.resortids,
                showSpecificActivityAvailability: options.showSpecificActivityAvailability,
                resourceId: dynamicFilter.resourceid,
                selectedDay: dynamicFilter.startdate,
            });
            this.setState({
                availableDates,
            });
            if (!dynamicFilter.startdate) {
                const availableDatesArray = Object.values(availableDates).sort((a, b) => (a.isBefore(b) ? -1 : 1));
                const firstAvailableDate = availableDatesArray[0]?.format(DATE_FORMAT.DEFAULT);
                if (firstAvailableDate) {
                    const payload = {
                        startdate: firstAvailableDate,
                        enddate: firstAvailableDate,
                    };
                    const startDateAction: FilterChangeAction = {
                        type: ActionType.FilterChange,
                        filter: dynamicFilterType.startdate,
                        payload,
                    };
                    const endDateAction: FilterChangeAction = {
                        type: ActionType.FilterChange,
                        filter: dynamicFilterType.enddate,
                        payload,
                    };
                    this.props.dispatchAction(startDateAction);
                    if (!options.showActivityAvailability) {
                        this.props.dispatchAction(endDateAction);
                    }
                }
            }
        } else if (myEnvState?.selectedReservationId && options.dateType === DEPARTURE && options.enableStayExtension) {
            const env = await getMxtsEnv(context, context.currentLocale.code);
            const currentReservation = myEnvState.selectedReservation ?? (await MxtsApi.getReservation(env, {}, [{ key: "reservationId", value: myEnvState.selectedReservationId }]));
            const resourceId = currentReservation.reservedResources?.[0]?.resourceId;
            const unitId = currentReservation.reservedResources?.[0]?.unitId;
            const distributionChannelId = currentReservation.reservation.distributionChannelId;
            if (currentReservation.reservation && resourceId && unitId && distributionChannelId) {
                const departureDate = currentReservation.reservation.departureDate;
                const requestParams: ResourceDateAvailabilitySearchRequest = {
                    arrivalDate: currentReservation.reservation.arrivalDate,
                    bookDate: currentReservation.reservation.reservationDate,
                    departureDate,
                    resourceId,
                    unitId,
                    distributionChannelId,
                    startDate: null,
                    endDate: null,
                    releaseStatus: "OPEN",
                    ignoreRentability: false,
                    preBooking: false,
                };
                const availableDepartureDateMoments = await MxtsApi.getAvailableDepartures(env, requestParams).then((result) =>
                    result.resourceAvailabilities.reduce((acc: moment.Moment[], dates) => {
                        const dateMoment = moment(dates.date, DATE_FORMAT.MXTS);
                        if (dateMoment.isAfter(departureDate)) {
                            acc.push(dateMoment);
                        }
                        return acc;
                    }, [])
                );
                const availableDepartureDates = createDateMap([...availableDepartureDateMoments]);
                this.setState({ availableDates: availableDepartureDates });
            }
        } else if (response) {
            let availableDatesArray: Moment[] = [];
            if (this.isArrival || (props.options.dateRange && !response.filter.arrivalDate)) {
                availableDatesArray = response.response.arrivalDates?.map((date: number) => moment(date).utc()) || [];
            } else if (response.filter.arrivalDate) {
                const arrivalDate = new Date(response.filter.arrivalDate);
                const durations = response.response.durations;
                if (durations) {
                    availableDatesArray = durations.map((duration: number) => {
                        const tempDate = new Date(arrivalDate);
                        tempDate.setDate(tempDate.getDate() + duration);
                        const mom = moment(tempDate);
                        return mom;
                    });
                }
            }
            const availableDates: DateMap = createDateMap(availableDatesArray);
            const availableDurations: number[] = [];
            if (response.response.durations && response.response.durations[0] !== null) {
                availableDurations.push(...response.response.durations);
            } else {
                for (let i = 1; i < 366; i++) {
                    availableDurations.push(i);
                }
            }
            this.setState({
                availableDates,
                availableDurations,
            });
        } else {
            const availableDurations: number[] = [];
            for (let i = 1; i < 366; i++) {
                availableDurations.push(i);
            }
            const availableDatesArray = availableDurations!.map((duration: number) => {
                const tempDate = new Date();
                tempDate.setDate(tempDate.getDate() + duration);
                const momentDate = moment(tempDate);
                return momentDate;
            });
            const allDates: DateMap = createDateMap(availableDatesArray);
            this.setState({
                availableDurations,
                availableDates: allDates,
            });
        }
    };
    private applyResults = () => {
        const { startDate, endDate } = this.state;
        this.setState({ isApplyClicked: true, displayDateRangePicker: false, dateRangeModalOpen: false, finalStartDate: startDate, finalEndDate: endDate }, () => {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.updateDates,
                payload: {
                    ...this.props.dynamicFilter,
                    startdate: startDate ? startDate.format(DATE_FORMAT.DEFAULT) : undefined,
                    enddate: endDate ? endDate.format(DATE_FORMAT.DEFAULT) : undefined,
                },
            };
            this.props.dispatchAction(action);
            this.getDateLabel(startDate ? startDate : undefined, endDate ? endDate : undefined);
        });
    };

    private clearResults = () => {
        this.setState({ startDate: null, endDate: null, focusedInput: "startDate", finalStartDate: null, finalEndDate: null });
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.removeFilters,
            payload: {
                ...this.props.dynamicFilter,
                startdate: undefined,
                enddate: undefined,
            },
        };
        this.props.dispatchAction(action);
        this.getDateLabel(undefined, undefined);
    };

    private toggleCalendar = () => {
        const { displayDateRangePicker, finalEndDate, finalStartDate, isApplyClicked } = this.state;
        this.setState({ displayDateRangePicker: !displayDateRangePicker, focusedInput: "startDate" });
        if (!isApplyClicked) {
            this.setState({ startDate: finalStartDate, endDate: finalEndDate });
        }
    };

    private getDateLabel = (startDate?: Moment, endDate?: Moment) => {
        const { options } = this.props;
        const dash = " - ";
        const startDateLabel = startDate && startDate.format(options.dateFormat || DATE_FORMAT.DISPLAY);
        /* jscpd:ignore-start */
        const endDateLabel = endDate && endDate.format(options.dateFormat || DATE_FORMAT.DISPLAY);
        const dateLabel =
            startDateLabel && endDateLabel && startDateLabel !== endDateLabel ? (
                <span>
                    {startDateLabel}
                    {dash}
                    {endDateLabel}
                </span>
            ) : (
                <span>{startDateLabel}</span>
            );
        this.setState({ dateLabel });
        /* jscpd:ignore-end */
    };

    private isVerticalCalendarScrollEnabled() {
        const { options } = this.props;
        return options.enableVerticalCalendarScrolling && (options.orientationOfCalendar === CalendarOrientation.VERTICAL || this.state.isMobile);
    }

    private getDateRangePicker() {
        const { startDate, endDate, availableDates, availableDurations, focusedInput, isComponentMounted, modalLabel } = this.state;
        const { options, childOptions, dynamicFilter, context } = this.props;
        const { currentLocale, site } = context;
        const withAdvancedClearButton = options.addAdvancedClearButton ? "withClearBtn" : "";
        const startDateId = `availabilityDateRangePickerStartDate-${withAdvancedClearButton}`;
        const endDateId = `availabilityDateRangePickerEndDate-${withAdvancedClearButton}`;
        const noOfMonthsObj = this.isVerticalCalendarScrollEnabled() ? { numberOfMonths: 14 } : {};
        const startDateLabelElement = options.showStartEndDateLabel ? (
            <span className={`start-date-label start-date-label-${this.randomKey}`}> {getI18nLocaleString(namespacesList.admin, "arrivalLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        const endDateLabelElement = options.showStartEndDateLabel ? (
            <span className={`end-date-label end-date-label-${this.randomKey}`}> {getI18nLocaleString(namespacesList.admin, "departureLabelAbovePlaceholder", currentLocale, site)} </span>
        ) : (
            ""
        );
        const startDateCloseButtonElement = (
            <FontAwesome
                name="remove"
                onClick={this.handleRemoveDates.bind(this, dynamicFilterType.startdate)}
                className={`${!dynamicFilter.startdate && "hidden"} remove-startdate  remove-startdate-${this.randomKey}`}
            />
        );
        const endDateCloseButtonElement = (
            <FontAwesome
                name="remove"
                onClick={this.handleRemoveDates.bind(this, dynamicFilterType.enddate)}
                className={`${!dynamicFilter.enddate && "hidden"} remove-enddate remove-enddate-${this.randomKey}`}
            />
        );
        /* jscpd:ignore-start */
        const changeDefaultWidthDaySize = options?.showCustomData && options.customDataDisplayType === "inline" ? +options.daySizeWidth : undefined;
        return (
            <div className={`${options.showCustomData ? "custom-data-in-calendar" : ""}`}>
                <DateRangePickerWrapper
                    startDateId={startDateId}
                    endDateId={endDateId}
                    startDatePlaceholderText={getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "arrivalPlaceholder", currentLocale, site)}
                    endDatePlaceholderText={getI18nLocaleString(namespacesList.dynamicAvailabilityDate, "departurePlaceholder", currentLocale, site)}
                    disabled={childOptions ? !childOptions.isEditable : false}
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={this.handleDatesChange}
                    focusedInput={options.modalPopupCalendar ? null : focusedInput}
                    onFocusChange={this.handleFocusChange}
                    displayFormat={options.dateFormat}
                    orientation={this.state.isMobile ? CalendarOrientation.VERTICAL : options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                    showClearDates={options.addInlineClearButton}
                    availableDates={availableDates}
                    availableDurations={availableDurations}
                    showBackdrop={!options.modalPopupCalendar}
                    dynamicFilter={dynamicFilter}
                    context={context}
                    showCustomData={options?.showCustomData}
                    customDataDisplayType={options?.customDataDisplayType}
                    showTotalPrices={options?.showTotalPrices}
                    showPerNightPrices={options?.showPerNightPrices}
                    showHighlightedDates={dynamicFilter?.specialcode && options?.showCustomData}
                    showOnlySpecialDates={dynamicFilter?.specialcode && options?.showCustomData}
                    specialAvailableDurations={this.state?.modalSpecialAvailableDurations}
                    specialAvailableDates={this.state?.specialArrivalDateMap}
                    localeForDatePicker={options.useCustomLocale ? options?.localeForDatePicker : ""}
                    {...noOfMonthsObj}
                    daySize={changeDefaultWidthDaySize}
                    modalLabel={modalLabel}
                    showCloseIcon={options?.closeIcon}
                    showClearAndApplyButton={options?.showClearAndApplyButton}
                    onCalendarApply={this.applyResults}
                    onCalendarClear={this.clearResults}
                    keepOpenOnDateSelect={options?.showClearAndApplyButton}
                />
                {isComponentMounted && options.addAdvancedClearButton && startDateCloseButtonElement}
                {isComponentMounted && options.addAdvancedClearButton && endDateCloseButtonElement}
                {isComponentMounted && options.showStartEndDateLabel && startDateLabelElement}
                {isComponentMounted && options.showStartEndDateLabel && endDateLabelElement}
            </div>
        );
        /* jscpd:ignore-end */
    }
}

function mapStateToProps(state: State): DateFacetStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        activityPlannerState: state.activityPlannerState,
        availabilityState: state.availabilityState,
        myEnvState: state.myEnvState,
        isFrontendEditable: state.frontendPageEditState.isFrontEndEditable,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction | ActivityPlannerAction>): DateFacetDispatchProps {
    return { dispatchAction: dispatch };
}

async function ensureFetchingElasticResponse(props: DateFacetProps): Promise<void> {
    const { availabilityState, options, context, dispatchAction, dynamicFilter, isActivityPlannerWidget } = props;
    if (!availabilityState?.availabilityResult && !options.disableAvailability && !availabilityState?.fetching && !options.enableStayExtension && !isActivityPlannerWidget) {
        await fetchElasticResponse(context, dynamicFilter, dispatchAction, context.currentLocale.code);
    }
}

// eslint-disable-next-line max-len
const DateFacet = connect<DateFacetStoreProps, DateFacetDispatchProps>(mapStateToProps, mapDispatchToProps)(DateFacetWidget);

const DateFacetWithEditModal = withEditModal(DateFacet);
export const DynamicDateFacet = wrapProps<DateFacetBaseProps>(DateFacetWithEditModal);
export async function warmupCache(props: DateFacetProps): Promise<void> {
    await ensureFetchingElasticResponse(props);
}
