import * as React from "react";

import { NumberMultiSelectOption } from "../../plugins/mxts/selectOption.types";

interface ButtonGroupProps {
    buttons: NumberMultiSelectOption[]; // List of button group.
    onButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
    defaultSelectedButtonIndex?: number; // Index of button that needs to be selected initially.
}
const ButtonGroup = (props: ButtonGroupProps) => {
    const { buttons, onButtonClick, defaultSelectedButtonIndex } = props;
    const [clickedId, setClickedId] = React.useState<number>(-1);

    React.useEffect(() => {
        if (defaultSelectedButtonIndex !== undefined) {
            setClickedId(defaultSelectedButtonIndex);
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
        setClickedId(id);
        onButtonClick(event);
    };

    return (
        <>
            {(buttons || []).map((button, i: number) => (
                <button key={i} name={String(button.value)} onClick={(event) => handleClick(event, i)} className={i === clickedId ? "btn flexible-button-group active" : "flexible-button-group"}>
                    {button.label as string}
                </button>
            ))}
        </>
    );
};

export default ButtonGroup;
