import * as React from "react";

import { Badge } from "reactstrap";
import { getI18nLocaleString } from "../../../../../i18n";
import namespaceList from "../../../../../i18n/namespaceList";

export const Legend = () => (
    <div data-testid="legend" className="legend">
        <Badge className="available">{getI18nLocaleString(namespaceList.dynamicAvailabilityDate, "available")}</Badge>
        <Badge className="booked">{getI18nLocaleString(namespaceList.dynamicAvailabilityDate, "booked")}</Badge>
    </div>
);
