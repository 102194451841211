import { GroupOptions, InputSpecGroup, localized } from "../form-specs";
import { capitalize, lowerCase } from "lodash";

import { LocalizedContentBase } from "@maxxton/cms-api";
import { SelectOption } from "../form-specs/formSpec.types";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface TooltipOnEmptyInputOptions {
    useTooltipOnEmptyInput?: boolean; // Can be used to toggle a Tooltip tab on a widget spec
    tooltipOnEmptyInputOptions?: TooltipOnEmptyInputSpecOptions;
}

export interface TooltipOnEmptyInputSpecOptions {
    localizedMessages?: LocalizedTooltipMessage[];
    placement?: TooltipPlacement;
    onlyShowInitially?: boolean;
    useCloseIcon?: boolean;
}

export interface LocalizedTooltipMessage extends LocalizedContentBase {
    tooltipMessage?: string;
}

/**
 * This is an enum version of the Popper Placement type used by the reactstrap Tooltip component.
 */
export enum TooltipPlacement {
    AUTO_START = "auto-start",
    AUTO = "auto",
    AUTO_END = "auto-end",
    TOP_START = "top-start",
    TOP = "top",
    TOP_END = "top-end",
    RIGHT_START = "right-start",
    RIGHT = "right",
    RIGHT_END = "right-end",
    BOTTOM_START = "bottom-start",
    BOTTOM = "bottom",
    BOTTOM_END = "bottom-end",
    LEFT_START = "left-start",
    LEFT = "left",
    LEFT_END = "left-end",
}

/**
 * Converts the Tooltip Placement enum values into Select options that can be used in a select input.
 */
export const getTooltipPlacementSelectOptions = (): Array<SelectOption<TooltipPlacement>> =>
    (Object.keys(TooltipPlacement) as Array<keyof typeof TooltipPlacement>).map(
        (placementOption: keyof typeof TooltipPlacement): SelectOption<TooltipPlacement> => ({
            label: capitalize(lowerCase(placementOption)),
            value: TooltipPlacement[placementOption],
        })
    );

/* eslint-disable max-lines-per-function */
export const tooltipOnEmptyInputSpec = <E, P extends keyof E>({
    options,
}: {
    options: GroupOptions<E, P>;
}): InputSpecGroup<E, P, TooltipOnEmptyInputSpecOptions, keyof TooltipOnEmptyInputSpecOptions> => ({
    variable: options.variable,
    visible: options.visible,
    type: "group",
    specs: [
        localized({
            variable: "localizedMessages",
            label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "localizedMessageInputDescription"),
            tabContent: [
                {
                    variable: "tooltipMessage",
                    label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "tooltipMessageLabel"),
                    type: "text",
                },
            ],
        }),
        {
            variable: "onlyShowInitially",
            label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "onlyShowTooltipInitially"),
            type: "checkbox",
            default: false,
        },
        {
            variable: "placement",
            label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "tooltipPlacement"),
            type: "select",
            optionList: getTooltipPlacementSelectOptions(),
        },
        {
            variable: "useCloseIcon",
            label: getI18nLocaleObject(namespaceList.tooltipOnEmptyInput, "useCloseIcon"),
            type: "checkbox",
            default: false,
        },
    ],
});
