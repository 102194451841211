import * as React from "react";
import * as moment from "moment";

import { CMSProviderProperties } from "../../../../containers/cmsProvider.types";
import { CalendarOrientation } from "../../owner/owner.util";
import { DateMap } from "../../../mxts/mxts.types";
import { DayPickerSingleDateControllerWrapper } from "../../../../components/datepicker/DayPickerSingleDateController/DayPickerSingleDateControllerWrapper";
import { DynamicFilter } from "../../../../redux/reducers/dynamicFilter.types";
import { Legend } from "./Legend/Legend";
import { WidgetOptions } from "../";

interface RequiredPropsForCustomData {
    dynamicFilter?: DynamicFilter;
    context?: CMSProviderProperties;
    showCustomData?: boolean;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    availableDates?: DateMap;
    availableDurations?: number[];
    specialAvailableDates?: DateMap;
    specialAvailableDurations?: number[];
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
}

interface AvailabilityCalendarProps {
    availableDates?: DateMap;
    options: WidgetOptions;
    requiredPropsForCustomData?: RequiredPropsForCustomData;
}

const defaultNumberOfMonthsInRow = "4";
const defaultNumberOfMonthsToShow = "4";

export const AvailabilityCalendar = ({ availableDates, options, requiredPropsForCustomData }: AvailabilityCalendarProps) => {
    const colCount = +(options.numberOfMonthsInRow || (defaultNumberOfMonthsInRow as string));
    const months = options.showXAvailabilityCalendar ? +options.numberOfMonthsToShow! || +defaultNumberOfMonthsToShow! : 12;
    const rowCount = Math.ceil(months / colCount);

    return (
        <div data-testid="availability-calendar" className="availability-calendar">
            {Array(rowCount)
                .fill(0)
                .map((_, index) => {
                    const date = !options.showXAvailabilityCalendar ? moment().month(index * colCount) : moment().add(index * colCount, "month");
                    const numberOfMonths = index + 1 === rowCount ? months - index * colCount : colCount;
                    return (
                        <DayPickerSingleDateControllerWrapper
                            {...requiredPropsForCustomData}
                            key={index}
                            date={date}
                            focused={true}
                            numberOfMonths={numberOfMonths}
                            orientation={options.orientationOfCalendar || CalendarOrientation.HORIZONTAL}
                            availableDates={availableDates}
                            showBackdrop={false}
                            renderCalendarInfo={index + 1 === rowCount ? Legend : null}
                        />
                    );
                })}
        </div>
    );
};
