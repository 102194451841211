import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { getI18nLocaleString } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

type Moment = moment.Moment;

export function InputDayPickerRangeControllerWrapper(props: {
    children: React.ReactNode;
    context?: CMSProviderProperties;
    showInputs?: boolean;
    inputDateFormat?: string;
    setFocusedInput: React.Dispatch<React.SetStateAction<string | null>>;
    startDate: Moment | undefined | null;
    endDate: Moment | undefined | null;
    setStartDate: React.Dispatch<React.SetStateAction<Moment | undefined | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Moment | undefined | null>>;
    hideClearDateButtons?: boolean;
    modalLabel?: string;
}) {
    const { children, hideClearDateButtons, inputDateFormat, setStartDate, setEndDate, setFocusedInput, startDate, endDate, showInputs, context, modalLabel } = props;

    const handleStartDateChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>): void => {
            if (event.target instanceof HTMLInputElement) {
                setStartDate(moment(event.target.value, inputDateFormat || DATE_FORMAT.DISPLAY));
            } else {
                setStartDate(null);
                setFocusedInput("startDate");
            }
        },
        [setStartDate, inputDateFormat, setFocusedInput]
    );
    const handleEndDateChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>): void => {
            if (event.target instanceof HTMLInputElement) {
                setEndDate(moment(event.target.value, inputDateFormat || DATE_FORMAT.DISPLAY));
            } else {
                setEndDate(null);
                if (startDate) {
                    setFocusedInput("endDate");
                } else {
                    setFocusedInput("startDate");
                }
            }
        },
        [setEndDate, inputDateFormat, setFocusedInput, startDate]
    );

    return (
        <div className={`input-field-wrapper ${showInputs ? "input-field-enabled" : "input-field-disabled"}`}>
            <div></div>
            {showInputs && (
                <div className={`dayRangePickerInputs ${modalLabel ? "input-field-spacing" : ""}`} id="dayRangePickerInputsId">
                    <div className="startdateInputs">
                        <div className="dateAndLabel">
                            <label className="startDateLabel">{getI18nLocaleString(namespaceList.admin, "arrivalLabelAbovePlaceholder", context?.currentLocale, context?.site)}</label>
                            <input
                                className="startDateInput"
                                type="text"
                                value={startDate ? startDate.format(inputDateFormat || DATE_FORMAT.DISPLAY) : ""}
                                placeholder={getI18nLocaleString(namespaceList.admin, "startDate")}
                                onChange={handleStartDateChange}
                                onClick={() => setFocusedInput("startDate")}
                            />
                        </div>
                        {startDate && !hideClearDateButtons && <FontAwesome role="button" onClick={handleStartDateChange} name="remove" className="remove-startdate" />}
                    </div>

                    <div className="enddateInputs">
                        <div className="dateAndLabel">
                            <label className="endDateLabel">{getI18nLocaleString(namespaceList.admin, "departureLabelAbovePlaceholder", context?.currentLocale, context?.site)}</label>
                            <input
                                className="endDateInput"
                                type="text"
                                value={endDate ? endDate.format(inputDateFormat || DATE_FORMAT.DISPLAY) : ""}
                                placeholder={getI18nLocaleString(namespaceList.admin, "endDate")}
                                onChange={handleEndDateChange}
                                onClick={() => setFocusedInput("endDate")}
                            />
                        </div>
                        {endDate && !hideClearDateButtons && <FontAwesome role="button" onClick={handleEndDateChange} name="remove" className="remove-endtdate" />}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
}
