import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { SingleDatePicker, SingleDatePickerShape } from "react-dates";
import { getPrices, handleMonthChangeEvent, isDayBlocked, isDayHighlighted, isOutsideRange, loadPrices } from "../datepicker.util";
import { isEqual, noop, omit } from "lodash";

import { Arrival } from "@maxxton/cms-mxts-api";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CalendarFormActions } from "../CalendarFormActions/CalendarFormActions";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateMap } from "../../../plugins/mxts/mxts.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { initialVisibleMonthThunk } from "../../../plugins/mxts";

type Moment = moment.Moment;
interface SingleDatePickerWrapperState {
    blockPrevMonthRender: boolean;
    availableDatesDetail?: Arrival[];
}

export type SingleDatePickerWrapperProps = SingleDatePickerShape & {
    availableDates?: DateMap;
    specialAvailableDates?: DateMap;
    onCalendarClose: () => void;
    showCloseIcon: boolean;
    showHighlightedDates: boolean;
    showBackdrop: boolean;
    showOnlySpecialDates: boolean;
    onMonthChange: (day: Moment) => void;
    dynamicFilter?: DynamicFilter;
    context?: CMSProviderProperties;
    showCustomData?: boolean;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
};

const defaultProps = {
    focused: false,
    date: null,
    id: "date",
    placeholder: "Date",
    numberOfMonths: 1,
    firstDayOfWeek: 1,
    showCloseIcon: false,
    displayFormat: DATE_FORMAT.DISPLAY,
    onDateChange: noop,
    onFocusChange: noop,
    onCalendarClose: noop,
    hideKeyboardShortcutsPanel: true,
    showHighlightedDates: false,
    showBackdrop: true,
    readOnly: true,
    showOnlySpecialDates: false,
    showDefaultInputIcon: true,
    customInputIcon: <FontAwesome name="calendar" />,
    onMonthChange: noop,
};

class SingleDatePickerWrapper extends React.Component<SingleDatePickerWrapperProps, SingleDatePickerWrapperState> {
    static defaultProps = defaultProps;

    constructor(props: SingleDatePickerWrapperProps) {
        super(props);
        const { date } = props;
        const dates = this.getDates();
        const blockPrevMonthRender = dates && Object.keys(dates).length && date ? AvailabilityUtil.isFirstAvailableMonth({ dateMap: dates, day: date }) : true;
        this.state = {
            blockPrevMonthRender,
            availableDatesDetail: [],
        };
    }

    componentDidMount() {
        loadPrices(this, true);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<SingleDatePickerWrapperProps>): void {
        const { availableDates, dynamicFilter } = this.props;
        if (!isEqual(availableDates, nextProps.availableDates) || !isEqual(dynamicFilter, nextProps.dynamicFilter)) {
            loadPrices(this, true);
        }
    }

    componentDidUpdate(prevProps: SingleDatePickerWrapperProps) {
        const { availableDates, specialAvailableDates, showOnlySpecialDates, date } = this.props;
        const specialAvailableDatesChanged = showOnlySpecialDates && !prevProps.specialAvailableDates && specialAvailableDates;
        const availableDatesChanged = !showOnlySpecialDates && !prevProps.availableDates && availableDates;

        if ((specialAvailableDatesChanged || availableDatesChanged) && date) {
            const dates = this.getDates();
            const blockPrevMonthRender = AvailabilityUtil.isFirstAvailableMonth({ dateMap: dates, day: date });
            this.setState({ blockPrevMonthRender });
        }
    }

    public render(): JSX.Element {
        const { blockPrevMonthRender, availableDatesDetail } = this.state;
        const { showCloseIcon, date, specialAvailableDates, showHighlightedDates, focused, showBackdrop, dynamicFilter, customDataDisplayType, showPerNightPrices, showCustomData } = this.props;
        const singleDatePickerProps = omit(this.props, [
            "availableDates",
            "specialAvailableDates",
            "showCloseIcon",
            "onCalendarClose",
            "showHighlightedDates",
            "showOnlySpecialDates",
            "showBackdrop",
            "onMonthChange",
            "dynamicFilter",
            "showCustomData",
            "customDataDisplayType",
            "context",
            "showPerNightPrices",
            "showTotalPrices",
        ]) as SingleDatePickerShape;
        const showDayHighlighted = showHighlightedDates ? isDayHighlighted(specialAvailableDates) : undefined;
        const dates = this.getDates();
        const firstAvailableDay: Moment | undefined = AvailabilityUtil.getFirstAvailableDay({ dateMap: dates });
        const renderCalendarInfo = showCloseIcon ? this.getCalendarFormActions : undefined;
        const startDate = date || firstAvailableDay;

        const paramToFetchPrices = {
            startDate,
            endDate: null,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            availableDatesDetail,
            isSingleDatePicker: true,
        };

        return (
            <div className={classNames(blockPrevMonthRender && "prev-disabled")}>
                <div className={`${focused && showBackdrop ? "backdrop-popup" : ""}`}></div>
                <SingleDatePicker
                    {...singleDatePickerProps}
                    isDayBlocked={isDayBlocked(dates)}
                    isOutsideRange={isOutsideRange(firstAvailableDay)}
                    isDayHighlighted={showDayHighlighted}
                    initialVisibleMonth={initialVisibleMonthThunk(dates, date)}
                    renderCalendarInfo={renderCalendarInfo}
                    readOnly
                    onNextMonthClick={(currentMonthDate) => this.onMonthClick(currentMonthDate, "Next")}
                    onPrevMonthClick={(currentMonthDate) => this.onMonthClick(currentMonthDate, "Prev")}
                    // eslint-disable-next-line react/jsx-no-bind
                    renderDayContents={(day, modifiers) => <React.Fragment>{showCustomData ? getPrices({ ...paramToFetchPrices, day, modifiers }) : day.format("D")}</React.Fragment>}
                />
            </div>
        );
    }

    private getDates = (): DateMap | undefined => {
        const { availableDates, specialAvailableDates, showOnlySpecialDates } = this.props;
        const dates = showOnlySpecialDates ? specialAvailableDates : availableDates;
        return dates;
    };

    private onMonthClick = (day: Moment, type: string): void => {
        const { availableDates, onMonthChange } = this.props;
        onMonthChange(day);
        const blockPrevMonthRender = availableDates ? AvailabilityUtil.isFirstAvailableMonth({ dateMap: availableDates, day }) : true;
        if (this.state.blockPrevMonthRender !== blockPrevMonthRender) {
            this.setState({ blockPrevMonthRender });
        }
        handleMonthChangeEvent(day, this, type);
    };

    private getCalendarFormActions = (): JSX.Element => {
        const { onCalendarClose, showCloseIcon } = this.props;
        return <CalendarFormActions onCalendarClose={onCalendarClose} showCloseIcon={showCloseIcon} />;
    };
}

export { SingleDatePickerWrapper };
