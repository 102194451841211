import * as React from "react";

interface ToggleSwitchProps {
    onSwitchToggleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isDefaultSwitchToggled?: boolean;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
    const { onSwitchToggleClick, isDefaultSwitchToggled } = props;
    const [isToggled, setIsToggled] = React.useState(!!isDefaultSwitchToggled);
    const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsToggled(!isToggled);
        onSwitchToggleClick(e);
    };

    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={isToggled} onChange={onToggle} />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleSwitch;
